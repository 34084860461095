import { useEffect, useContext } from "react";
import "simplebar-react/dist/simplebar.min.css";

import { DashboardContext } from "../../context/dashboard";

import Menu from "./menu";
import GeneralStats from "./general-stats";
import DistributionByNeighborhood from "./distribution-by-neighborhood";
import DistributionByType from "./distribution-by-type";
import RecentlyAdded from "./recently-added";

export default function Index() {
  const { stats } = useContext(DashboardContext);

  return (
    <>
      <div className="container-fluid relative px-3">
        <div className="layout-specing">
          <Menu />

          <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 mt-6 gap-6">
            <GeneralStats stats={stats} />
          </div>

          <div className="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-6">
            <DistributionByNeighborhood stats={stats} />
            <DistributionByType stats={stats} />
          </div>

          <div className="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-6">
            <RecentlyAdded />
          </div>
        </div>
      </div>
    </>
  );
}
