import React from "react";
import { Link } from "react-router-dom";

import bg1 from "../assets/images/01.jpg";
import client from "../assets/images/client/07.jpg";
import BackToHome from "../components/backToHome";
import Switcher from "../components/switcher";

export default function LockScreen() {
  return (
    <>
      <section
        className="h-screen flex items-center justify-center relative overflow-hidden bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
        <div className="container relative">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
            <div className="p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
              <div className="text-center">
                <img
                  src={client}
                  className="mx-auto size-20 rounded-full shadow dark:shadow-gray-800"
                  alt=""
                />
                <h5 className="mb-6 mt-4 text-xl font-semibold">
                  Calvin Carlo
                </h5>
              </div>
              <form>
                <div className="grid grid-cols-1">
                  <div className="mb-4 ltr:text-left rtl:text-right">
                    <label className="font-semibold" htmlFor="LoginPassword">
                      Password:
                    </label>
                    <input
                      id="LoginPassword"
                      type="password"
                      className="form-input mt-3 rounded-md"
                      required=""
                      placeholder="Password:"
                    />
                  </div>

                  <div className="flex justify-between mb-4">
                    <div className="inline-flex items-center mb-0">
                      <input
                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-green-600 focus:border-green-300 focus:ring focus:ring-offset-0 focus:ring-green-200 focus:ring-opacity-50 me-2"
                        type="checkbox"
                        value=""
                        id="RememberMe"
                      />
                      <label
                        className="form-checkbox-label text-slate-400"
                        htmlFor="RememberMe"
                      >
                        Remember me
                      </label>
                    </div>
                    <p className="text-slate-400 mb-0">
                      <Link to="/reset-password" className="text-slate-400">
                        Forgot password ?
                      </Link>
                    </p>
                  </div>

                  <div className="mb-4">
                    <input
                      type="submit"
                      className="btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md w-full"
                      value="Login / Sign in"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <BackToHome />
      <Switcher />
    </>
  );
}
