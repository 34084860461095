import { useEffect, useState, useContext } from "react";

import { supabase } from "../../services/supabase";

import Menu from "./menu";
import Pagination from "./pagination";
import PropertyGrid from "./property-grid";

import { useAuth } from "../../context/auth";
import { UserProfileContext } from "../../context/user";

export default function FavoriteProperty() {
  const [posts, setPosts] = useState([]);

  const { user } = useAuth();
  const { removeUserFavorite } = useContext(UserProfileContext);

  const fetchUserFavorites = async () => {
    const { data } = await supabase
      .from("user_profile")
      .select("favorites")
      .single();
    return data.favorites;
  };

  const fetchFavoriteProperties = async (favorites) => {
    const { data, error } = await supabase
      .from("data")
      .select()
      .in("id", favorites);

    if (error) {
      console.error("Error fetching favorite properties:", error.message);
      throw error;
    }
    return data;
  };

  const run = async () => {
    const favorites = await fetchUserFavorites();
    const favoriteProperties = await fetchFavoriteProperties(favorites);

    setPosts(favoriteProperties);
  };

  useEffect(() => {
    run();
  }, []);

  if (posts.length === 0) {
    return (
      <div className="container-fluid relative px-3">
        <div className="layout-specing">
          <Menu />
          <div className="text-center py-5">
            <h1 className="text-2xl font-semibold">
              Nenhuma propriedade favorita encontrada
            </h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <Menu />
        <PropertyGrid
          posts={posts}
          setPosts={setPosts}
          user={user}
          removeUserFavorite={removeUserFavorite}
        />
        {/* <Pagination /> */}
      </div>
    </div>
  );
}
