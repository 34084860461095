import { Link } from "react-router-dom";

export default function Support() {
  const config = {
    whatsapp: "+5511999999999",
    phone: "+5511999999999",
    email: "contato@imovisor.com",
  };
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">Suporte</h5>

          <ul className="tracking-[0.5px] inline-block sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[16px] font-medium duration-500 dark:text-white/70 hover:text-green-600 dark:hover:text-white">
              <Link to="/index">Imovisor</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right"></i>
            </li>
            <li
              className="inline-block capitalize text-[16px] font-medium text-green-600 dark:text-white"
              aria-current="page"
            >
              Suporte
            </li>
          </ul>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1 mt-6 gap-2">
          {/* show a centralized message */}
          <div className="md:col-span-12 col-span-1">
            <div className="flex items-center justify-center h-[300px] bg-gray-100 dark:bg-slate-900 text-gray-500 dark:text-gray-400">
              <div className="text-center">
                <h1 className="text-2xl font-semibold">
                  Nossos canais de comunicação
                </h1>
                {/* create a list with contact information along with icons. whatsapp, email and phone */}
                <ul className="flex flex-col items-start justify-center mt-3 gap-y-3">
                  <li className="mx-2 flex items-center gap-x-2">
                    <a
                      href={`tel:${config.phone}`}
                      className="text-green-600 dark:text-green-500"
                    >
                      <i className="mdi mdi-phone text-2xl"></i>
                    </a>
                    <span> {config.phone} </span>
                  </li>
                  <li className="mx-2 flex items-center gap-x-2">
                    <a
                      href={`https://wa.me/${config.whatsapp.replace("+", "")}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-green-600 dark:text-green-500"
                    >
                      <i className="mdi mdi-whatsapp text-2xl"></i>
                    </a>
                    <span> {config.whatsapp} </span>
                  </li>
                  <li className="mx-2 flex items-center gap-x-2">
                    <a
                      href={`mailto:${config.email}`}
                      className="text-green-600 dark:text-green-500"
                    >
                      <i className="mdi mdi-email text-2xl"></i>
                    </a>
                    <span> {config.email} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
