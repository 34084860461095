export default function PropertyGridSkeleton() {
  const posts = Array(6).fill(0);

  return (
    <div className="property-list grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mt-6">
      {posts.map((_item, index) => {
        return (
          <div
            className="property-item group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-700 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500 animate-pulse"
            key={index}
          >
            {/* IMAGEM E BOTAO DE FAVORITAR */}
            <div className="relative">
              <div className="bg-slate-700 h-96 w-full"></div>
            </div>

            {/* DETALHES DO IMOVEL */}
            <div className="p-6">
              <div className="pb-6">
                <div className="h-2 w-72 bg-slate-700 rounded"></div>
              </div>

              <ul className="py-6 border-y border-slate-100 dark:border-gray-800 flex items-center list-none">
                <li className="flex items-center me-4">
                  <div className="h-2 w-20 bg-slate-700 rounded"></div>
                </li>

                <li className="flex items-center me-4">
                  <div className="h-2 w-20 bg-slate-700 rounded"></div>
                </li>

                <li className="flex items-center">
                  <div className="h-2 w-20 bg-slate-700 rounded"></div>
                </li>
              </ul>

              <ul className="pt-6 flex justify-between items-center list-none">
                <li>
                  <div className="h-2 w-32 bg-slate-700 rounded"></div>
                </li>

                <li>
                <div className="h-2 w-32 bg-slate-700 rounded"></div>
                </li>
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
}
