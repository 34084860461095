import axios from "axios";
import { createContext, useState, useEffect } from "react";
import { edge_functions_base_url } from "../services/supabase";

export const DashboardContext = createContext({});

export const http = axios.create({
  baseURL: edge_functions_base_url,
});

const fetchStats = async () => {
  const response = await http.get("/stats");
  return response.data;
};

export const DashboardProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    fetchStats().then((data) => {
      setStats(data);
      setLoading(false);
    });
  }, []);

  return (
    <DashboardContext.Provider value={{ fetchStats, stats }}>
      {!loading && children}
    </DashboardContext.Provider>
  );
};
