import { useEffect, useContext } from "react";

import PropertyGrid from "./property-grid";
import Pagination from "./pagination";
import Menu from "./menu";
import Filter from "./filter";

import { useAuth } from "../../context/auth";
import { PropertyListingContext } from "../../context/property";
import { UserProfileContext } from "../../context/user";

export default function ExploreProperty() {
  const {
    loading,
    setLoading,
    postsCount,
    posts,
    currentPage,
    setCurrentPage,
    postsPerPage,
    fetchPostsCount,
    fetchPosts,
    filters,
  } = useContext(PropertyListingContext);

  const { user } = useAuth();

  const {
    userFavorites,
    fetchUserFavorites,
    removeUserFavorite,
    insertUserFavorite,
  } = useContext(UserProfileContext);

  const run = async () => {
    setLoading(true);
    await fetchPostsCount(filters);
    await fetchPosts(filters);
    await fetchUserFavorites();
    setLoading(false);
  };

  useEffect(() => {
    run();
  }, []);

  useEffect(() => {
    run();
  }, [currentPage]);

  return (
    <>
      <div className="container-fluid relative px-3">
        <div className="layout-specing">
          <Menu />
          <Filter />
          <PropertyGrid
            loading={loading}
            postsCount={postsCount}
            posts={posts}
            user={user}
            userFavorites={userFavorites}
            removeUserFavorite={removeUserFavorite}
            insertUserFavorite={insertUserFavorite}
          />
          <Pagination
            loading={loading}
            postsCount={postsCount}
            currentPage={currentPage}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
}
