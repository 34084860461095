import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { supabase, edge_functions_base_url } from "../../services/supabase";
import { BrazillianReal } from "../../util";

import Menu from "./menu";
import PropertyPhotos from "./property-photos";

const http = axios.create({
  baseURL: edge_functions_base_url,
});

export default function PropertyDetail() {
  const params = useParams();
  const [property, setProperty] = useState(undefined);
  const [nearbyPlaces, setNearbyPlaces] = useState(undefined);

  const maps_config = {
    MAP_MODE: "place",
    API_KEY: "AIzaSyD-pZxL8cazXiAtl143gbz5DHq3-E-G05o",
  };

  const fetchProperty = async (id) => {
    const { data } = await supabase.from("data").select("*").eq("id", id);
    if (data.length === 0) {
      setProperty(undefined);
    } else {
      setProperty(data[0]);
    }
  };

  const fetchNearbyPlaces = async () => {
    console.log("[+] Searching for nearby places..");
    const response = await http.post("/nearby-places", {
      state: property.state,
      final_city: property.final_city,
      final_neighborhood: property.final_neighborhood,
    });
    setNearbyPlaces(response.data);
  };

  useEffect(() => {
    fetchProperty(params.id);
  }, []);

  // aguarda os dados da propriedade serem obtidos e busca por lugares proximos
  useEffect(() => {
    if (property) {
      fetchNearbyPlaces();
    }
  }, [property]);

  if (!property) return <h2 variant="h2">Loading..</h2>;

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <Menu source={property.source} />
        <PropertyPhotos photos={property.photos} />

        <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-6 mt-6">
          <div className="lg:col-span-8">
            <div className="bg-white dark:bg-slate-900 p-6 rounded-md shadow dark:shadow-gray-700">
              <h4 className="text-2xl font-medium">
                {property?.title ? property.title : "?"}
              </h4>

              <ul className="py-6 flex items-center list-none">
                <li className="flex items-center lg:me-6 me-4">
                  <i className="mdi mdi-arrow-expand-all lg:text-3xl text-2xl me-2 text-green-600"></i>
                  <span className="lg:text-xl">
                    {property?.total_area ? property.total_area : "?"}
                  </span>
                </li>

                <li className="flex items-center lg:me-6 me-4">
                  <i className="mdi mdi-bed lg:text-3xl text-2xl me-2 text-green-600"></i>
                  <span className="lg:text-xl">
                    {property?.bedrooms ? property.bedrooms : "?"}
                  </span>
                </li>

                <li className="flex items-center">
                  <i className="mdi mdi-shower lg:text-3xl text-2xl me-2 text-green-600"></i>
                  <span className="lg:text-xl">
                    {property?.bathrooms ? property.bathrooms : "?"}
                  </span>
                </li>
              </ul>

              <p className="text-slate-400">
                {property?.description ? property.description : "?"}
              </p>

              <div className="w-full leading-[0] border-0 mt-6">
                <iframe
                  src={`https://www.google.com/maps/embed/v1/${maps_config.MAP_MODE}?key=${maps_config.API_KEY}&q=${property.location}`}
                  style={{ border: "0" }}
                  title="Imovisor"
                  className="w-full h-[500px]"
                  allowFullScreen
                ></iframe>
              </div>

              <h4 className="text-lg font-thin capitalize text-slate-400 mt-2 text-end">
                {`${property?.final_neighborhood}, ${property?.final_city} - ${property?.state}`}
              </h4>
            </div>
          </div>

          <div className="lg:col-span-4">
            <div className="rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-700">
              <div className="p-6">
                <h5 className="text-2xl font-medium">Preço:</h5>

                <div className="flex justify-between items-center mt-4">
                  <span className="text-xl font-medium">
                    {BrazillianReal.format(property.price)}
                  </span>

                  <span className="bg-green-600/10 text-green-600 text-sm px-2.5 py-0.75 rounded h-6">
                    {property.business_type == "venda"
                      ? "à venda"
                      : "pra alugar"}
                  </span>
                </div>

                <ul className="list-none mt-4">
                  <li className="flex justify-between items-center">
                    <span className="text-slate-400 text-sm">
                      Idade do anúncio
                    </span>
                    <span className="font-medium text-sm">12 Dias</span>
                  </li>

                  <li className="flex justify-between items-center mt-2">
                    <span className="text-slate-400 text-sm">Preço por m2</span>
                    <span className="font-medium text-sm">
                      {BrazillianReal.format(
                        Math.ceil(property.price / property.total_area),
                      )}
                    </span>
                  </li>

                  <li className="flex justify-between items-center mt-2">
                    <span className="text-slate-400 text-sm">
                      Custo mensal (estimado)
                    </span>
                    <span className="font-medium text-sm">-</span>
                  </li>
                </ul>
              </div>

              <div className="flex">
                <div className="p-1 w-1/2">
                  <Link
                    to="#!"
                    className="btn bg-green-600 hover:bg-green-700 text-white rounded-md w-full"
                  >
                    Contatar Anunciante
                  </Link>
                </div>
                <div className="p-1 w-1/2">
                  <Link
                    to={`${property.URL}`}
                    target="_blank"
                    className="btn bg-green-600 hover:bg-green-700 text-white rounded-md w-full"
                  >
                    Ver Anúncio Original
                  </Link>
                </div>
              </div>
            </div>

            <div className="mt-12 text-center">
              <h3 className="mb-6 text-xl leading-normal font-medium text-black dark:text-white">
                Deseja ter mais detalhes ? Entre em contato com o anunciante!
              </h3>

              <div className="mt-6">
                <Link
                  to="#!"
                  className="btn bg-transparent hover:bg-green-600 border border-green-600 text-green-600 hover:text-white rounded-md"
                >
                  <i className="mdi mdi-whatsapp align-middle me-2"></i>{" "}
                  WhatsApp
                </Link>
              </div>
            </div>
          </div>

          <div className="lg:col-span-12">
            <div className="rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 p-6">
              <h5 className="text-2xl font-medium mb-4">
                Comodidades próximas a propriedade
              </h5>
              <div className="grid grid-cols-1 md:grid-cols-3">
                {!nearbyPlaces ? (
                  <p> Carregando </p>
                ) : (
                  <>
                    <div className="supermarket md:mt-2">
                      <ul className="list-disc">
                        <p className="font-medium">Supermercados</p>
                        {nearbyPlaces.supermarket &&
                          nearbyPlaces.supermarket.map((item) => (
                            <li key={item}>{item}</li>
                          ))}
                      </ul>
                    </div>
                    <div className="pharmacy mt-8 md:mt-2">
                      <ul className="list-disc">
                        <p className="font-medium">Farmácias</p>
                        {nearbyPlaces.pharmacy &&
                          nearbyPlaces.pharmacy.map((item) => (
                            <li key={item}>{item}</li>
                          ))}
                      </ul>
                    </div>
                    <div className="hospital mt-8 md:mt-2">
                      <ul className="list-disc">
                        <p className="font-medium">Saúde</p>
                        {nearbyPlaces.hospital &&
                          nearbyPlaces.hospital.map((item) => (
                            <li key={item}>{item}</li>
                          ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 mt-2">
                {!nearbyPlaces ? (
                  <p> Carregando </p>
                ) : (
                  <>
                    <div className="gym mt-8 md:mt-2">
                      <ul className="list-disc">
                        <p className="font-medium">Fitness</p>
                        {nearbyPlaces.gym &&
                          nearbyPlaces.gym.map((item) => (
                            <li key={item}>{item}</li>
                          ))}
                      </ul>
                    </div>
                    <div className="bakery mt-8 md:mt-2">
                      <ul className="list-disc">
                        <p className="font-medium">Cafés / Padarias</p>
                        {nearbyPlaces.bakery &&
                          nearbyPlaces.bakery.map((item) => (
                            <li key={item}>{item}</li>
                          ))}
                      </ul>
                    </div>
                    <div className="school mt-8 md:mt-2">
                      <ul className="list-disc">
                        <p className="font-medium">Educação</p>
                        {nearbyPlaces.school &&
                          nearbyPlaces.school.map((item) => (
                            <li key={item}>{item}</li>
                          ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
