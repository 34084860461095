import axios from "axios";
import { createContext, useState, useEffect } from "react";
import { edge_functions_base_url } from "../services/supabase";

export const AiSearchContext = createContext({});

export const http = axios.create({
  baseURL: edge_functions_base_url,
});

const searchProperties = async (userQuery) => {
  const response = await http.post("/ai-search", { user_query: userQuery });
  return response.data;
};

export const AiSearchProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <AiSearchContext.Provider value={{ searchProperties }}>
      {!loading && children}
    </AiSearchContext.Provider>
  );
};
