import { useEffect, useState, useContext } from 'react';
import Select from 'react-select'
import { LuSearch } from "react-icons/lu";
import { RxHome } from "react-icons/rx";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { LuBriefcase } from "react-icons/lu";
import { LuBedSingle } from "react-icons/lu";
import { LuDatabase } from "react-icons/lu";
import { LuParkingCircle } from "react-icons/lu";

import { supabase } from "../../services/supabase";
import { BrazillianReal, reverseCurrencyFormat } from "../../util";
import { PropertyListingContext } from '../../context/property';


const PropertyType = [
    { value: 'all', label: 'Todos' },
    { value: 'casa', label: 'Casa' },
    { value: 'apartamento', label: 'Apartamento' },
    { value: 'lote', label: 'Lote' },
    { value: 'loja', label: 'Loja' },
    { value: 'chacara', label: 'Chacara' },
    { value: 'rural', label: 'Rural' },
    { value: 'predio', label: 'Predio' },
    { value: 'galpao', label: 'Galpão' },
    { value: 'outro', label: 'Outro' },
]

const dealType = [
    { value: 'all', label: 'Todos'},
    { value: 'venda', label: 'Venda'},
    { value: 'aluguel', label: 'Aluguel' },
    { value: 'outro', label: 'Outro' },
]

const minBedrooms = [
    { value: '0', label: '0' },
    { value: '1', label: '1+' },
    { value: '2', label: '2+' },
    { value: '3', label: '3+' },
    { value: '4', label: '4+' },
    { value: '5', label: '5+' },
]

const minParkingSpaces = [
    { value: '0', label: '0' },
    { value: '1', label: '1+' },
    { value: '2', label: '2+' },
    { value: '3', label: '3+' },
    { value: '4', label: '4+' },
    { value: '5', label: '5+' },
]

export default function Filter() {
    const [config, setConfig] = useState(null);
    const [sources, setSources] = useState([]);
    const [selectedSource, setSelectedSource] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedDealType, setSelectedDealType] = useState(null);
    const [propertyType, setPropertyType] = useState(null);
    const [selectedMinPrice, setSelectedMinPrice] = useState('');
    const [selectedMaxPrice, setSelectedMaxPrice] = useState('');
    const [selectedMinBedrooms, setSelectedMinBedrooms] = useState(null);
    const [selectedMinParkingSpaces, setSelectedMinParkingSpaces] = useState(null);

    const { postsCount, fetchPostsCount, fetchPosts, setFilters} = useContext(PropertyListingContext);
    

    const fetchConfig = async () => {
        const { data } = await supabase.from("config").select("*");
        return data;
    }

    const handleLocationChange = (e) => {
        setSelectedLocation(e.target.value);
    }

    const handleMinPriceChange = (e) => {
        setSelectedMinPrice(e.target.value);
    }

    const handleMaxPriceChange = (e) => {
        setSelectedMaxPrice(e.target.value);
    }

    const queryData = async (_filters) => {
        await fetchPostsCount(_filters);
        await fetchPosts(_filters);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let _filters = [];

        if(selectedSource && selectedSource.value !== 'all')
            _filters.push({column: "source", "operator": "eq", "value": selectedSource.value});

        if(selectedLocation)
            _filters.push({column: "location", "operator": "ilike", "value": `%${selectedLocation}%`});

        if(selectedDealType && selectedDealType.value !== 'all')
            _filters.push({column: "business_type", "operator": "eq", "value": selectedDealType.value});

        if(propertyType && propertyType.value !== 'all')
            _filters.push({column: "real_estate_type", "operator": "eq", "value": propertyType.value});

        if(selectedMinPrice)
            _filters.push({column: "price", "operator": "gte", "value": reverseCurrencyFormat(selectedMinPrice)});

        if(selectedMaxPrice)
            _filters.push({column: "price", "operator": "lte", "value": reverseCurrencyFormat(selectedMaxPrice)});

        if(selectedMinBedrooms)
            _filters.push({column: "bedrooms", "operator": "gte", "value": selectedMinBedrooms.value});

        if(selectedMinParkingSpaces)
            _filters.push({column: "parking_spaces", "operator": "gte", "value": selectedMinParkingSpaces.value});


        setFilters(_filters);
        queryData(_filters);
    }

    // obtem a configuração
    useEffect(() => {
        fetchConfig().then(data => {
            setConfig(data[0]);
        })
    }, []);

    // obtem as fontes
    useEffect(() => {
        if(config) {
            setSources([{value: 'all', label: 'Todos'}, ...config["sources"].map(item => ({ value: item, label: item }))]);
        }
    }, [config]);


    if(sources.length === 0) return <h1>Loading..</h1>

    return (
        <div className="grid grid-cols-1 mt-6">
            
            <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700">
                <div className="registration-form text-dark text-start">
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">

                        <div>
                            <label htmlFor="buy-min-price" className="form-label text-slate-900 dark:text-white font-medium">Fonte:</label>                                                        
                            <div className="filter-search-form relative filter-border mt-2">
                                <LuDatabase className="icons"/>
                                <Select 
                                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" 
                                    options={sources} 
                                    defaultValue={selectedSource}
                                    onChange={setSelectedSource}
                                    placeholder="Selecione a fonte"
                                />

                            </div>
                        </div>

                        <div>
                            <label className="form-label text-slate-900 dark:text-white font-medium">Localização:</label>
                            <div className="filter-search-form relative filter-border mt-2">
                                <LuSearch className="icons"/>
                                <input 
                                    type="text" 
                                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" 
                                    placeholder="Digite bairro, cidade ou região" 
                                    value={selectedLocation} 
                                    onChange={handleLocationChange}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="buy-properties" className="form-label text-slate-900 dark:text-white font-medium">Pretenção:</label>                                                        
                            <div className="filter-search-form relative filter-border mt-2">
                                <LuBriefcase className="icons"/>
                                <Select 
                                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" 
                                    options={dealType} 
                                    defaultValue={selectedDealType}
                                    onChange={setSelectedDealType}
                                    placeholder="Tipo de negociação"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="buy-properties" className="form-label text-slate-900 dark:text-white font-medium">Tipo de propriedade:</label>                                                        
                            <div className="filter-search-form relative filter-border mt-2">
                                <RxHome className="icons"/>
                                <Select 
                                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" 
                                    options={PropertyType} 
                                    defaultValue={propertyType}
                                    onChange={setPropertyType}
                                    placeholder="Tipo de propriedade"
                                />
                            </div>
                        </div>

                        <div className="lg:mt-6">
                            <label className="form-label text-slate-900 dark:text-white font-medium">Preço minimo:</label>
                            <div className="filter-search-form relative filter-border mt-2">
                                <AiOutlineDollarCircle className="icons"/>
                                <input 
                                    type="text" 
                                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" 
                                    placeholder="Preço minimo" 
                                    value={selectedMinPrice}
                                    onChange={handleMinPriceChange}
                                    onBlur={(e) => {
                                        if(e.target.value !== '') {
                                            setSelectedMinPrice(BrazillianReal.format(e.target.value))
                                        }
                                    }}
                                    onFocus={(e) => {
                                        if(e.target.value !== '') {
                                            setSelectedMinPrice(reverseCurrencyFormat(e.target.value));
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="lg:mt-6">
                            <label className="form-label text-slate-900 dark:text-white font-medium">Preço máximo:</label>
                            <div className="filter-search-form relative filter-border mt-2">
                                <AiOutlineDollarCircle className="icons"/>
                                <input 
                                    type="text" 
                                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" 
                                    placeholder="Preço máximo" 
                                    value={selectedMaxPrice}
                                    onChange={handleMaxPriceChange}
                                    onBlur={(e) => {
                                        if(e.target.value !== '') {
                                            setSelectedMaxPrice(BrazillianReal.format(e.target.value))
                                        }
                                    }}
                                    onFocus={(e) => {
                                        if(e.target.value !== '') {
                                            setSelectedMaxPrice(reverseCurrencyFormat(e.target.value));
                                        }
                                    }}
                                />
                            </div>
                        </div>


                        <div className="lg:mt-6">
                            <label htmlFor="buy-min-price" className="form-label text-slate-900 dark:text-white font-medium">Quartos:</label>                                                        
                            <div className="filter-search-form relative filter-border mt-2">
                                <LuBedSingle className="icons"/>
                                <Select 
                                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" 
                                    options={minBedrooms} 
                                    defaultValue={selectedMinBedrooms}
                                    onChange={setSelectedMinBedrooms}
                                    placeholder="Quantidade de quartos"
                                />
                            </div>
                        </div>

                        <div className="lg:mt-6">
                            <label htmlFor="buy-min-price" className="form-label text-slate-900 dark:text-white font-medium">Vagas:</label>                                                        
                            <div className="filter-search-form relative filter-border mt-2">
                                <LuParkingCircle className="icons"/>
                                <Select 
                                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" 
                                    options={minParkingSpaces} 
                                    defaultValue={selectedMinParkingSpaces}
                                    onChange={setSelectedMinParkingSpaces}
                                    placeholder="Quantidade de vagas de garagem"
                                />
                            </div>
                        </div>

                        <div className="lg:mt-12">
                            <input 
                                type="submit" 
                                name="search" 
                                className="btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white searchbtn submit-btn w-full !h-12 rounded hover:cursor-pointer" 
                                value="Buscar" 
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>

                    <div className="flex mt-4">
                        <h3 className="text-gray-500">{postsCount ? postsCount : 0} propriedades retornadas</h3>
                    </div>
                </div>
            </form>

        </div>
    )
}