import "./assets/css/tailwind.css";
import "./App.css";
import "./assets/css/materialdesignicons.min.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Index from "./pages/index";
import Sidebar from "./components/sidebar";
import TopHeader from "./components/topHeader";
import { useState } from "react";
import Footer from "./components/footer";
import Switcher from "./components/switcher";
import ExploreProperty from "./pages/explore-property";
import PropertyDetail from "./pages/property-detail";
import FavoriteProperty from "./pages/favorite-property";
import AddProperty from "./pages/add-property";
import Support from "./pages/support";
import Profile from "./pages/profile";
import ProfileSetting from "./pages/profile-setting";
import Blog from "./pages/blog";
import BlogDetail from "./pages/blog-detail";
import Starter from "./pages/starter";
import Faqs from "./pages/faqs";
import Pricing from "./pages/pricing";
import Review from "./pages/review";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Login from "./pages/login";
import Signup from "./pages/signup";
import SignupSuccess from "./pages/signup-success";
import ResetPassword from "./pages/reset-password";
import LockScreen from "./pages/lock-screen";
import Comingsoon from "./pages/comingsoon";
import Maintenance from "./pages/maintenance";
import Error from "./pages/error";
import ThankYou from "./pages/thankyou";
import Test from "./pages/test";
import AiSearch from "./pages/ai-search";

// react context api
import { AuthProvider } from "./context/auth";
import { PropertyListingProvider } from "./context/property";
import { UserProfileProvider } from "./context/user";
import { DashboardProvider } from "./context/dashboard";
import { AiSearchProvider } from "./context/aisearch";

import AuthRoute from "./components/AuthRoute";

function App() {
  let [toggle, setToggle] = useState(false);
  let loction = useLocation();
  return (
    <>
      <AuthProvider>
        {loction.pathname === "/login" ||
        loction.pathname === "/signup" ||
        loction.pathname === "/signup-success" ||
        loction.pathname === "/reset-password" ||
        loction.pathname === "/lock-screen" ||
        loction.pathname === "/comingsoon" ||
        loction.pathname === "/maintenance" ||
        loction.pathname === "/error" ||
        loction.pathname === "/thankyou" ? (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup-success" element={<SignupSuccess />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/lock-screen" element={<LockScreen />} />
            <Route path="/comingsoon" element={<Comingsoon />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/error" element={<Error />} />
            <Route path="*" element={<Error />} />
            <Route path="/thankyou" element={<ThankYou />} />
          </Routes>
        ) : (
          <div className={`${toggle ? "" : "toggled"} page-wrapper`}>
            <Sidebar />
            <main className="page-content bg-gray-50 dark:bg-slate-800">
              <TopHeader toggle={toggle} setToggle={setToggle} />

              <Routes>
                <Route element={<AuthRoute />}>
                  <Route
                    path="/"
                    element={
                      <DashboardProvider>
                        <Index />
                      </DashboardProvider>
                    }
                  />
                  <Route
                    path="/index"
                    element={
                      <DashboardProvider>
                        <Index />
                      </DashboardProvider>
                    }
                  />
                  <Route
                    path="/explore-property"
                    element={
                      <UserProfileProvider>
                        <PropertyListingProvider>
                          <ExploreProperty />
                        </PropertyListingProvider>
                      </UserProfileProvider>
                    }
                  />
                  <Route
                    path="/ai-search"
                    element={
                      <AiSearchProvider>
                        <AiSearch />
                      </AiSearchProvider>
                    }
                  />
                  <Route
                    path="/property-detail"
                    element={
                      <PropertyListingProvider>
                        <PropertyDetail />
                      </PropertyListingProvider>
                    }
                  />
                  <Route
                    path="/property-detail/:id"
                    element={
                      <PropertyListingProvider>
                        <PropertyDetail />
                      </PropertyListingProvider>
                    }
                  />
                  <Route
                    path="/favorite-property"
                    element={
                      <UserProfileProvider>
                        <PropertyListingProvider>
                          <FavoriteProperty />
                        </PropertyListingProvider>
                      </UserProfileProvider>
                    }
                  />
                  {/* <Route path="/add-property" element={<AddProperty />} /> */}
                  <Route path="/support" element={<Support />} />
                  <Route
                    path="/profile"
                    element={
                      <UserProfileProvider>
                        <Profile />
                      </UserProfileProvider>
                    }
                  />
                  <Route
                    path="/profile-setting"
                    element={
                      <UserProfileProvider>
                        <ProfileSetting />
                      </UserProfileProvider>
                    }
                  />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/blog-detail" element={<BlogDetail />} />
                  <Route path="/blog-detail/:id" element={<BlogDetail />} />
                  <Route path="/starter" element={<Starter />} />
                  <Route path="/faqs" element={<Faqs />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/review" element={<Review />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/test" element={<Test />} />
                </Route>
              </Routes>
              <Footer />
              <Switcher />
            </main>
          </div>
        )}
      </AuthProvider>
    </>
  );
}

export default App;
