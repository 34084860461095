import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { FaWhatsapp } from "react-icons/fa6";
import {
  FiMail,
  FiGlobe,
  FiCheck,
  FiLoader,
} from "react-icons/fi";

import banner from "../../assets/images/jason-dent-w3eFhqXjkZE-unsplash.jpg";

import { UserProfileContext } from "../../context/user";
import { CiLocationOn } from "react-icons/ci";

export default function Profile() {
  const { userData, fetchUserData } = useContext(UserProfileContext);

  useEffect(() => {
    fetchUserData();
  }, []);

  if (!userData) return <h1>Loading..</h1>;

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="grid grid-cols-1">
          <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
            <div className="relative shrink-0">
              <img
                src={banner}
                className="h-80 w-full object-cover"
                id="profile-banner"
                alt=""
              />
              <div className="absolute inset-0 bg-black/70"></div>
              <label className="absolute inset-0" htmlFor="pro-banner"></label>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1">
          <div className="xl:col-span-3 lg:col-span-4 md:col-span-4 mx-6">
            <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 -mt-48">
              <div className="profile-pic text-center mb-5">
                <div>
                  <div className="relative size-40 mx-auto">
                    <img
                      src={userData.avatar_url}
                      className="shadow rounded-xl dark:shadow-gray-700 ring-4 ring-slate-50 dark:ring-slate-800"
                      id="profile-image"
                      alt="profile image"
                      width={200}
                      height={200}
                    />
                    <label
                      className="absolute inset-0"
                      htmlFor="pro-img"
                    ></label>
                  </div>

                  <div className="mt-24">
                    <h5 className="text-lg font-semibold">{userData.name}</h5>
                  </div>
                </div>
              </div>

              <div className="border-t border-gray-100 dark:border-gray-700">
                <h5 className="text-xl font-semibold mt-4">
                  Detalhes pessoais:
                </h5>
                <div className="mt-4">
                  <div className="flex items-center">
                    <FiMail className="fea icon-ex-md text-slate-400 me-3 size-6" />
                    <div className="flex-1">
                      <h6 className="text-green-600 dark:text-white font-medium mb-0">
                        Email:
                      </h6>
                      <Link to="" className="text-slate-400">
                        {userData.email}
                      </Link>
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <CiLocationOn className="fea icon-ex-md text-slate-400 me-3 size-6" />
                    <div className="flex-1">
                      <h6 className="text-green-600 dark:text-white font-medium mb-0">
                        Região:
                      </h6>
                      <Link to="" className="text-slate-400">
                        {userData.city || "?"} - {userData.state || "?"}
                      </Link>
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <FiCheck className="fea icon-ex-md text-slate-400 me-3 size-6" />
                    <div className="flex-1">
                      <h6 className="text-green-600 dark:text-white font-medium mb-0">
                        Membro desde:
                      </h6>
                      <p className="text-slate-400 mb-0">
                        {DateTime.fromISO(userData.created_at).toLocaleString({ timeZone: "America/Sao_Paulo" })}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <FiGlobe className="fea icon-ex-md text-slate-400 me-3 size-6" />
                    <div className="flex-1">
                      <h6 className="text-green-600 dark:text-white font-medium mb-0">
                        Zona de acesso:
                      </h6>
                      <Link to="" className="text-slate-400">
                        {userData.zone || "?"}
                      </Link>
                    </div>
                  </div>
                  <div className="flex items-center mt-3">
                    <FaWhatsapp className="fea icon-ex-md text-slate-400 me-3 size-6" />
                    <div className="flex-1">
                      <h6 className="text-green-600 dark:text-white font-medium mb-0">
                        Whatsapp:
                      </h6>
                      <Link to="" className="text-slate-400">
                        {userData.whatsapp || "?"}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:col-span-9 lg:col-span-8 md:col-span-8 mt-6">
            <div className="grid grid-cols-1 gap-6">
              <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div className="relative mt-2">
                  <FiLoader className="fea icon-ex-md text-slate-400 size-6 absolute" />
                  <h5 className="text-xl font-semibold ml-8"> Página em construção </h5>
                </div>

                <p className="text-slate-400 mt-3">
                  Essa página se encontra em desenvolvimento, em breve teremos estatísticas e informações relevantes como dados de acesso, histórico de atividades, gerenciamento de faturas e muito mais.
                </p>
                <p className="text-slate-400 mt-3">
                  Caso deseje visualizar ou alterar suas informações pessoais, acesse a página de <Link to="/profile/edit" className="text-green-600 dark:text-green-400">Configurações</Link>.
                </p>
              </div>

              {/* <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <h5 className="text-xl font-semibold"> SOME TITLE </h5>

                <h1> SOME CONTENT </h1>
              </div> */}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
