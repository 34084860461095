export const BrazillianReal = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const delay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
export function parseLocaleNumber(stringNumber, locale) {
  var thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  var decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), "."),
  );
}

export function reverseCurrencyFormat(value) {
  const exp = /^\w{0,3}\W?\s?(\d+)[.,](\d+)?,?(\d+)?$/g;
  const replacer = (f, group1, group2, group3) => {
    return group3 ? `${group1}${group2}.${group3}` : `${group1}.${group2}`;
  };

  return value.replace(exp, replacer);
}

/**
 * Gera uma mensagem de quanto tempo se passou de acordo com a data informada.
 * Fonte: ChatGPT (Assistente Imovisor)
 * @param {*} timestamp timestamp no formato timestampz
 * @returns Mensagem humanizada falando quanto tempo se passou desde a data informada.
 */
export function tempoDesdeNotificacao(timestamp) {
  const agora = new Date();
  const dataNotificacao = new Date(timestamp);
  const diferencaSegundos = Math.floor((agora - dataNotificacao) / 1000);

  const minutos = Math.floor(diferencaSegundos / 60);
  const horas = Math.floor(diferencaSegundos / 3600);
  const dias = Math.floor(diferencaSegundos / 86400);

  if (diferencaSegundos < 60) {
    return "Agora mesmo";
  } else if (minutos < 60) {
    return `${minutos} ${minutos === 1 ? "minuto" : "minutos"} atrás`;
  } else if (horas < 24) {
    return `${horas} ${horas === 1 ? "hora" : "horas"} atrás`;
  } else {
    return `${dias} ${dias === 1 ? "dia" : "dias"} atrás`;
  }
}
