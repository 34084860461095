import React from "react";
import { Link } from "react-router-dom";
import { people } from "../../data/data";
import Table from "./table";
import Pagination from "./pagination";

export default function Test() {
  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">Starter Page</h5>

          <ul className="tracking-[0.5px] inline-block sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[16px] font-medium duration-500 dark:text-white/70 hover:text-green-600 dark:hover:text-white">
              <Link to="/index">Hously</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right"></i>
            </li>
            <li
              className="inline-block capitalize text-[16px] font-medium text-green-600 dark:text-white"
              aria-current="page"
            >
              Test Page
            </li>
          </ul>
        </div>

        <div className="grid grid-cols-1 mt-6 gap-4 bg-white px-3">
          <Table people={people} />
          <Pagination />
        </div>
      </div>
    </div>
  );
}
