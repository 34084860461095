import { useState } from "react";
import { Link } from "react-router-dom";
import Lightbox from "react-18-image-lightbox";
import "../../../node_modules/react-18-image-lightbox/style.css";

// imagens padrao
import property1 from "../../assets/images/property/single/1.jpg";
import property2 from "../../assets/images/property/single/2.jpg";
import property3 from "../../assets/images/property/single/3.jpg";
import property4 from "../../assets/images/property/single/4.jpg";
import property5 from "../../assets/images/property/single/5.jpg";



export default function PropertyPhotos({ photos }) {
    let p1 = property1;
    let p2 = property2;
    let p3 = property3;
    let p4 = property4;
    let p5 = property5;

    // substitui pelas fotos da propriedade caso ela possua
    if(photos.length !== 0) {
      if(photos[0])
        p1 = photos[0]
      if(photos[1])
        p2 = photos[1]
      if(photos[2])
        p3 = photos[2]
      if(photos[3])
        p4 = photos[3]
      if(photos[4])
        p5 = photos[4]
    }

    let images = [p1, p2, p3, p4, p5];

    let [isOpen, setIsOpen] = useState(false);
    let [photoIndex, setActiveIndex] = useState(0);

    let handleCLick = (index) => {
        setActiveIndex(index);
        setIsOpen(true);
    };

    return (
        <>
        <div className="md:flex mt-4"> 
          <div className="lg:w-1/2 md:w-1/2 p-1">
            <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-700">
              <img src={p1} alt="" />
              <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
              <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                <Link
                  to="#"
                  onClick={() => handleCLick(0)}
                  className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox"
                >
                  <i className="mdi mdi-camera-outline"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="lg:w-1/2 md:w-1/2">
            <div className="flex">
              <div className="w-1/2 p-1">
                <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-700">
                  <img src={p2} alt="" />
                  <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                  <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                    <Link
                      to="#"
                      onClick={() => handleCLick(1)}
                      className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox"
                    >
                      <i className="mdi mdi-camera-outline"></i>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-1/2 p-1">
                <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-700">
                  <img src={p3} alt="" />
                  <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                  <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                    <Link
                      to="#"
                      onClick={() => handleCLick(2)}
                      className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox"
                    >
                      <i className="mdi mdi-camera-outline"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="w-1/2 p-1">
                <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-700">
                  <img src={p4} alt="" />
                  <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                  <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                    <Link
                      to="#"
                      onClick={() => handleCLick(3)}
                      className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox"
                    >
                      <i className="mdi mdi-camera-outline"></i>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="w-1/2 p-1">
                <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-700">
                  <img src={p5} alt="" />
                  <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                  <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center invisible group-hover:visible">
                    <Link
                      to="#"
                      onClick={() => handleCLick(4)}
                      className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox"
                    >
                      <i className="mdi mdi-camera-outline"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
            <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
                setActiveIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
                setActiveIndex((photoIndex + 1) % images.length)
            }
            />
        )}

    </>
    )
}