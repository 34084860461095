import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

import { supabase } from "../../services/supabase";
import { BrazillianReal } from "../../util";

const dtfmt = (date) => {
    return new Date(date).toLocaleDateString('pt-BR', {year: 'numeric', month: 'long', day: 'numeric'});
}

const RecentlyAdded = () => {
  const [data, setData] = useState([]);

  const fetchProperties = async () => {
    const { data, error } = await supabase.from("data").select("*").order("created_at", { ascending: false }).limit(10);
    if (error) {
        console.log("error", error);
    }
    else {
        setData(data);
    }
  };

  useEffect(() => {
    fetchProperties();
  } , []);

  if(data.length === 0) return <div>Loading...</div>;
  
  return (
    <div className="xl:col-span-12 lg:col-span-12 xl:order-2 order-3">
      <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
        <div className="p-6 flex items-center justify-between border-b border-gray-100 dark:border-gray-800">
          <h6 className="text-lg font-semibold">Adicionados Recentemente</h6>

          <Link
            to="/explore-property"
            className="btn btn-link font-normal text-slate-400 hover:text-green-600 after:bg-green-600 transition duration-500"
          >
            Ver todos <i className="mdi mdi-arrow-right ms-1"></i>
          </Link>
        </div>

        <SimpleBar className="relative overflow-x-auto block w-full xl:max-h-[284px] max-h-[350px]">
          <table className="w-full text-start">
            <thead className="text-base">
              <tr>
                <th className="text-start font-semibold text-[15px] px-4 py-3"></th>
                <th className="text-start font-semibold text-[15px] px-4 py-3 min-w-[140px]">
                  Data
                </th>
                <th className="text-start font-semibold text-[15px] px-4 py-3 min-w-[120px]">
                  Titulo
                </th>
                <th className="text-start font-semibold text-[15px] px-4 py-3">
                  Preço
                </th>
                <th className="text-start font-semibold text-[15px] px-4 py-3 min-w-[40px]">
                  Propósito
                </th>
                <th className="text-end font-semibold text-[15px] px-4 py-3 min-w-[70px]">
                  Fonte
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index}>
                    <th className="text-start border-t border-gray-100 dark:border-gray-800 px-4 py-3 font-semibold">
                      <div className="relative md:shrink-0">
                        <img
                          src={item.photos[0] || "https://via.placeholder.com/150"}
                          className="object-cover size-12 min-w-[48px] rounded-md shadow dark:shadow-gray-700"
                          alt=""
                        />
                      </div>
                    </th>
                    <td className="text-start border-t border-gray-100 dark:border-gray-800 px-4 py-3">
                      <span className="text-slate-400">{dtfmt(item.created_at)}</span>
                    </td>
                    <th className="text-start border-t border-gray-100 dark:border-gray-800 px-4 py-3 font-semibold">
                      {item.title}
                    </th>
                    <td className="text-start border-t border-gray-100 dark:border-gray-800 px-4 py-3">
                      <span className="text-slate-400">{BrazillianReal.format(item.price)}</span>
                    </td>
                    <th className="text-start border-t border-gray-100 dark:border-gray-800 px-4 py-3 font-semibold">
                      {item.business_type}
                    </th>
                    <td className="text-end border-t border-gray-100 dark:border-gray-800 px-4 py-3">
                      <span className="bg-emerald-600/10 dark:bg-emerald-600/20 border border-emerald-600/10 dark:border-emerald-600/20 text-blue-600 ">
                        {item.source}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SimpleBar>
      </div>
    </div>
  );
};

export default RecentlyAdded;