import React, { useState } from "react";
import { Link } from "react-router-dom";

import logoDark from "../../assets/images/new-logos/IMOVISOR-dark.png";
import logoLight from "../../assets/images/new-logos/IMOVISOR-light.png";
import bg1 from "../../assets/images/01.jpg";
import Switcher from "../../components/switcher";

import { supabase } from "../../services/supabase";

export default function Signup() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  // funcao de registro do supabase
  // ref.: https://supabase.com/docs/reference/javascript/auth-signup
  const register = (name, email, password) =>
    supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        data: {
          name: name,
        },
      },
    });

  // funcao para criar o perfil do usuario
  const create_profile = async (name, email, user_id) => {
    const { data, error } = await supabase.from("user_profile").insert([
      {
        user_id: user_id,
        name: name,
        email: email,
      },
    ]);

    return {
      data,
      error,
    };
  };

  // funcao para registrar o usuario e criar o perfil
  const register_and_create_profile = async (name, email, password) => {
    const response_register = await register(name, email, password);

    if (response_register.error) {
      alert("[-] Falha no cadastro");
      throw response_register.error;
    }

    const user = response_register.data.user;

    const response_profile = await create_profile(name, email, user.id);

    if (response_profile.error) {
      alert("[-] Falha ao criar perfil");
      throw response_profile.error;
    }

    return true;
  };

  function handleSubmit(e) {
    e.preventDefault();

    // verifica se o usuario aceitou os termos
    if (!acceptedTerms) {
      alert("Você precisa aceitar os termos e condições");
      return;
    }

    // verifica se os campos estao preenchidos
    if (!name || !email || !password) {
      alert("Preencha todos os campos");
      return;
    }

    // verifica se o email é valido usando regex
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      alert("E-mail inválido");
      return;
    }

    setLoading(true);

    // realiza o cadastro
    register_and_create_profile(name, email, password)
      .then(() => {
        alert("[+] Cadastro realizado com sucesso");
        setLoading(false);
      })
      .catch((error) => {
        console.error("[-] Falha no cadastro");
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <>
      <section
        className="h-screen flex items-center justify-center relative overflow-hidden bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
            <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
              <div className="p-6">
                <Link to="">
                  <img
                    src={logoDark}
                    className="mx-auto block dark:hidden"
                    alt=""
                  />
                  <img
                    src={logoLight}
                    className="mx-auto dark:block hidden"
                    alt=""
                  />
                </Link>
                <h5 className="my-6 text-xl font-semibold">Cadastro</h5>
                <form className="text-start">
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="RegisterName">
                        Nome:
                      </label>
                      <input
                        id="RegisterName"
                        type="text"
                        className="form-input mt-3"
                        placeholder="Seu nome"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginEmail">
                        E-mail:
                      </label>
                      <input
                        id="LoginEmail"
                        type="email"
                        className="form-input mt-3"
                        placeholder="Seu e-mail"
                        autoComplete="username"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginPassword">
                        Senha:
                      </label>
                      <input
                        id="LoginPassword"
                        type="password"
                        className="form-input mt-3"
                        placeholder="Sua senha"
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="mb-4">
                      <div className="flex items-center mb-0">
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-green-600 focus:border-green-300 focus:ring focus:ring-offset-0 focus:ring-green-200 focus:ring-opacity-50 me-2"
                          type="checkbox"
                          value=""
                          id="AcceptT&C"
                          onChange={(e) => setAcceptedTerms(e.target.checked)}
                        />
                        <label
                          className="form-check-label text-slate-400"
                          htmlFor="AcceptT&C"
                        >
                          Eu Aceito{" "}
                          <Link to="" className="text-green-600">
                            Termos e condições
                          </Link>
                        </label>
                      </div>
                    </div>

                    <div className="mb-4">
                      <button
                        className="btn bg-green-600 hover:bg-green-700 text-white rounded-md w-full"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        Cadastrar
                      </button>
                    </div>

                    <div className="text-center">
                      <span className="text-slate-400 me-2">
                        Já possui uma conta ?{" "}
                      </span>{" "}
                      <Link
                        to="/login"
                        className="text-black dark:text-white font-medium"
                      >
                        Login
                      </Link>
                    </div>
                  </div>
                </form>
              </div>

              <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
                <p className="mb-0 text-slate-400">
                  © {new Date().getFullYear()} Imovisor
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Switcher />
    </>
  );
}
