import { Link } from "react-router-dom";
import  { BrazillianReal } from "../../util";

const SourceBadge = ({source}) =>
(
  <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-sm font-medium text-green-700">
    <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
      <circle r={3} cx={3} cy={3} />
    </svg>
    {source}
  </span>
)

export default function PropertyGrid({ loading, postsCount, posts, user, userFavorites, removeUserFavorite, insertUserFavorite }) {
  
  if(loading || postsCount === 0) {
    return <h2 variant="h2"> Loading.. </h2>
  }

  if(!userFavorites) {
    return <h2 variant="h2"> Loading.. </h2>
  }

  // toggle favorite
  const isFavorite = (id) => {
    return userFavorites.includes(id);
  };

  // handle toggle favorite
  const handleFavorite = (e, id) => {
    e.target.setAttribute("disabled", "");

    if(isFavorite(id)) {
      removeUserFavorite(user.id, id)
      .then(() => {
        e.target.classList.remove("text-red-600");
        e.target.removeAttribute("disabled");
      });
    } else {
      insertUserFavorite(user.id, id)
      .then(() => {
        e.target.classList.add("text-red-600");
        e.target.removeAttribute("disabled");
      });
    }
  };

  return (
    <div className="property-list grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mt-6">
      {posts.map((item, index) => {
        return (
          <div
            className="property-item group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-700 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500"
            key={index}
          >
            {/* IMAGEM / FAVORITAR / FONTE */}
            <div className="relative">
              <img src={item.photos.length !== 0 ? item.photos[0] : "/images/property/6.jpg"} alt="property banner" />

              <div className="absolute top-4 end-4">
                <button
                  onClick={(e) => handleFavorite(e, item.id)}
                  className={`btn btn-icon bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-full ${!isFavorite(item.id) ? "text-slate-100 dark:text-slate-700" : "text-red-600 dark:text-red-600"}`}
                >
                  <i className="mdi mdi-heart text-[20px]"></i>
                </button>
              </div>

              <div className="absolute bottom-4 start-4">
                <SourceBadge source={item.source} />
              </div>

            </div>

            {/* DETALHES DO IMOVEL */}
            <div className="p-6">
              <div className="pb-6">
                <Link
                  to={`/property-detail/${item.id}`}
                  className="text-lg hover:text-green-600 font-medium ease-in-out duration-500"
                  data-id={item.id}
                >
                  {item.title}
                </Link>
              </div>

              <ul className="py-6 border-y border-slate-100 dark:border-gray-800 flex items-center list-none">
                <li className="flex items-center me-4">
                  <i className="mdi mdi-arrow-expand-all text-2xl me-2 text-green-600"></i>
                  <span>{item.total_area || item.building_area}m2</span>
                </li>

                <li className="flex items-center me-4">
                  <i className="mdi mdi-bed text-2xl me-2 text-green-600"></i>
                  <span>{item.bedrooms}</span>
                </li>

                <li className="flex items-center">
                  <i className="mdi mdi-shower text-2xl me-2 text-green-600"></i>
                  <span>{item.bathrooms}</span>
                </li>
              </ul>

              <ul className="pt-6 flex justify-between items-center list-none">
                <li>
                  <span className="text-slate-400">Preço</span>
                  <p className="text-lg font-medium">{BrazillianReal.format(item.price)}</p>
                </li>

                <li>
                  <span className="text-slate-400">Localização</span>
                  <p className="text-lg font-medium">{item.location}</p>
                </li>
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
}
