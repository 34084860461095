import property1 from "../assets/images/property/1.jpg";
import property2 from "../assets/images/property/2.jpg";
import property3 from "../assets/images/property/3.jpg";
import property4 from "../assets/images/property/4.jpg";
import property5 from "../assets/images/property/5.jpg";
import property6 from "../assets/images/property/6.jpg";
import property7 from "../assets/images/property/7.jpg";
import property8 from "../assets/images/property/8.jpg";
import property9 from "../assets/images/property/9.jpg";
import property10 from "../assets/images/property/10.jpg";
import property11 from "../assets/images/property/11.jpg";
import property12 from "../assets/images/property/12.jpg";

import client1 from "../assets/images/client/01.jpg";
import client2 from "../assets/images/client/02.jpg";
import client3 from "../assets/images/client/03.jpg";
import client4 from "../assets/images/client/04.jpg";
import client5 from "../assets/images/client/05.jpg";
import client6 from "../assets/images/client/06.jpg";
import client7 from "../assets/images/client/07.jpg";
import client8 from "../assets/images/client/08.jpg";
import client9 from "../assets/images/client/09.jpg";
import client10 from "../assets/images/client/10.jpg";
import client11 from "../assets/images/client/11.jpg";
import client12 from "../assets/images/client/12.jpg";
import client13 from "../assets/images/client/13.jpg";

export const people = [
  {
    id: 1,
    name: "Carlita Beasant",
    title: "Quality Control Specialist",
    email: "cbeasant0@e-recht24.de",
    company: "Daniel LLC",
  },
  {
    id: 2,
    name: "Cherise Glasheen",
    title: "Mechanical Systems Engineer",
    email: "cglasheen1@vistaprint.com",
    company: "Smitham Inc",
  },
  {
    id: 3,
    name: "Walker Sturmey",
    title: "Senior Quality Engineer",
    email: "wsturmey2@facebook.com",
    company: "Schmidt, Mayert and King",
  },
  {
    id: 4,
    name: "Casi Wooffitt",
    title: "Data Coordinator",
    email: "cwooffitt3@whitehouse.gov",
    company: "Larkin-Sanford",
  },
  {
    id: 5,
    name: "Isiahi Ellicott",
    title: "Assistant Manager",
    email: "iellicott4@buzzfeed.com",
    company: "Schultz-Witting",
  },
  {
    id: 6,
    name: "Kary Cocke",
    title: "Media Manager III",
    email: "kcocke5@google.it",
    company: "Wilkinson Group",
  },
  {
    id: 7,
    name: "Robbie Heed",
    title: "Environmental Tech",
    email: "rheed6@weibo.com",
    company: "Roob, Littel and Schowalter",
  },
  {
    id: 8,
    name: "Wilmer China",
    title: "Graphic Designer",
    email: "wchina7@macromedia.com",
    company: "Casper LLC",
  },
  {
    id: 9,
    name: "Teddie Panting",
    title: "Junior Executive",
    email: "tpanting8@vk.com",
    company: "Beer Group",
  },
  {
    id: 10,
    name: "Sophie Burgh",
    title: "Community Outreach Specialist",
    email: "sburgh9@harvard.edu",
    company: "Klein-Ratke",
  },
  {
    id: 11,
    name: "Sarena Pietraszek",
    title: "Recruiting Manager",
    email: "spietraszeka@reference.com",
    company: "Mann Group",
  },
  {
    id: 12,
    name: "Obadiah Ollivier",
    title: "Financial Advisor",
    email: "oollivierb@icq.com",
    company: "Terry-West",
  },
  {
    id: 13,
    name: "Patrick Kennerley",
    title: "Statistician IV",
    email: "pkennerleyc@ucsd.edu",
    company: "Harris-Watsica",
  },
  {
    id: 14,
    name: "Willdon Ivantsov",
    title: "Data Coordinator",
    email: "wivantsovd@cargocollective.com",
    company: "Wolff, Anderson and Bernier",
  },
  {
    id: 15,
    name: "Correy Battista",
    title: "Data Coordinator",
    email: "cbattistae@goo.gl",
    company: "Lakin and Sons",
  },
  {
    id: 16,
    name: "Theodor Fenna",
    title: "Physical Therapy Assistant",
    email: "tfennaf@bloglines.com",
    company: "Dach LLC",
  },
  {
    id: 17,
    name: "Ringo Blockley",
    title: "Database Administrator III",
    email: "rblockleyg@tinyurl.com",
    company: "Feest-Kuvalis",
  },
  {
    id: 18,
    name: "Zack Meritt",
    title: "Structural Engineer",
    email: "zmeritth@google.de",
    company: "Marquardt, Hermiston and Hackett",
  },
  {
    id: 19,
    name: "Tabbitha Boys",
    title: "Recruiting Manager",
    email: "tboysi@apple.com",
    company: "Stamm Inc",
  },
  {
    id: 20,
    name: "Giacinta Wonfar",
    title: "Help Desk Technician",
    email: "gwonfarj@nps.gov",
    company: "Cummings, Marks and Stark",
  },
  {
    id: 21,
    name: "Von Wadworth",
    title: "Staff Accountant I",
    email: "vwadworthk@mozilla.com",
    company: "Hilpert-Hayes",
  },
  {
    id: 22,
    name: "Sigismundo Matteris",
    title: "Account Executive",
    email: "smatterisl@rediff.com",
    company: "Larson-Pagac",
  },
  {
    id: 23,
    name: "Adolpho Elfitt",
    title: "Recruiting Manager",
    email: "aelfittm@harvard.edu",
    company: "Schoen-Zboncak",
  },
  {
    id: 24,
    name: "Cameron Mott",
    title: "Administrative Assistant IV",
    email: "cmottn@ocn.ne.jp",
    company: "Stehr-Schmitt",
  },
  {
    id: 25,
    name: "Fritz Jentzsch",
    title: "Administrative Officer",
    email: "fjentzscho@networksolutions.com",
    company: "Adams LLC",
  },
  {
    id: 26,
    name: "Ellene Gradley",
    title: "Statistician III",
    email: "egradleyp@scientificamerican.com",
    company: "Fisher, Stracke and Spinka",
  },
  {
    id: 27,
    name: "Josefa Risley",
    title: "Accountant II",
    email: "jrisleyq@jugem.jp",
    company: "Heller, Keebler and Miller",
  },
  {
    id: 28,
    name: "Wanids Conlaund",
    title: "Health Coach I",
    email: "wconlaundr@so-net.ne.jp",
    company: "Cronin, Volkman and Corwin",
  },
  {
    id: 29,
    name: "Bonita McManamen",
    title: "Occupational Therapist",
    email: "bmcmanamens@clickbank.net",
    company: "Stroman Group",
  },
  {
    id: 30,
    name: "Lexine Baise",
    title: "Food Chemist",
    email: "lbaiset@salon.com",
    company: "Friesen, Fay and Bailey",
  },
  {
    id: 31,
    name: "Averyl Ede",
    title: "Developer II",
    email: "aedeu@businessinsider.com",
    company: "Rowe Group",
  },
  {
    id: 32,
    name: "Donal Binge",
    title: "Community Outreach Specialist",
    email: "dbingev@ibm.com",
    company: "Gottlieb and Sons",
  },
  {
    id: 33,
    name: "Janetta Cabrales",
    title: "Dental Hygienist",
    email: "jcabralesw@stanford.edu",
    company: "Kohler-Pfannerstill",
  },
  {
    id: 34,
    name: "Meggie Gianullo",
    title: "Environmental Specialist",
    email: "mgianullox@europa.eu",
    company: "Denesik Inc",
  },
  {
    id: 35,
    name: "Gwendolyn Arlet",
    title: "Senior Cost Accountant",
    email: "garlety@twitter.com",
    company: "Boehm Group",
  },
  {
    id: 36,
    name: "Thorn Ferrant",
    title: "Marketing Assistant",
    email: "tferrantz@livejournal.com",
    company: "Daniel-Schaefer",
  },
  {
    id: 37,
    name: "Vivie Hebdon",
    title: "Research Assistant II",
    email: "vhebdon10@php.net",
    company: "Littel and Sons",
  },
  {
    id: 38,
    name: "Gabi Stanbrooke",
    title: "Automation Specialist IV",
    email: "gstanbrooke11@skyrock.com",
    company: "Watsica-West",
  },
  {
    id: 39,
    name: "Lacee Vlasenkov",
    title: "Software Engineer II",
    email: "lvlasenkov12@army.mil",
    company: "Robel, King and Purdy",
  },
  {
    id: 40,
    name: "Billie Branca",
    title: "Actuary",
    email: "bbranca13@php.net",
    company: "Crona, Yundt and Schamberger",
  },
  {
    id: 41,
    name: "Alberta Blinman",
    title: "Physical Therapy Assistant",
    email: "ablinman14@uol.com.br",
    company: "Bednar LLC",
  },
  {
    id: 42,
    name: "Packston Barck",
    title: "Staff Scientist",
    email: "pbarck15@timesonline.co.uk",
    company: "King-Kerluke",
  },
  {
    id: 43,
    name: "Peri MacRedmond",
    title: "Professor",
    email: "pmacredmond16@php.net",
    company: "Rutherford LLC",
  },
  {
    id: 44,
    name: "Jazmin Cragg",
    title: "Account Representative IV",
    email: "jcragg17@woothemes.com",
    company: "Ward Group",
  },
  {
    id: 45,
    name: "Matelda Catlette",
    title: "Financial Advisor",
    email: "mcatlette18@youtube.com",
    company: "O'Kon-Simonis",
  },
  {
    id: 46,
    name: "Hamlen Grayshon",
    title: "Teacher",
    email: "hgrayshon19@miitbeian.gov.cn",
    company: "Parker Inc",
  },
  {
    id: 47,
    name: "Hakim McSparran",
    title: "Nurse",
    email: "hmcsparran1a@toplist.cz",
    company: "Bogan-Howe",
  },
  {
    id: 48,
    name: "Renato Whiting",
    title: "Physical Therapy Assistant",
    email: "rwhiting1b@craigslist.org",
    company: "Cummings-Bechtelar",
  },
  {
    id: 49,
    name: "Curr Bartak",
    title: "Compensation Analyst",
    email: "cbartak1c@marriott.com",
    company: "Satterfield-Pfeffer",
  },
  {
    id: 50,
    name: "Courtney Ronayne",
    title: "Paralegal",
    email: "cronayne1d@cbc.ca",
    company: "Langosh-Frami",
  },
  {
    id: 51,
    name: "Tull Beddin",
    title: "Assistant Manager",
    email: "tbeddin1e@wiley.com",
    company: "Mueller-Swaniawski",
  },
  {
    id: 52,
    name: "Eba Ghiroldi",
    title: "Recruiter",
    email: "eghiroldi1f@yandex.ru",
    company: "Abshire-Hahn",
  },
  {
    id: 53,
    name: "Chaunce Kondratyuk",
    title: "Biostatistician IV",
    email: "ckondratyuk1g@usda.gov",
    company: "Little and Sons",
  },
  {
    id: 54,
    name: "Ninon Clemson",
    title: "Senior Sales Associate",
    email: "nclemson1h@dagondesign.com",
    company: "Hansen, Hirthe and Breitenberg",
  },
  {
    id: 55,
    name: "Constantine Yeend",
    title: "Sales Associate",
    email: "cyeend1i@1und1.de",
    company: "Rohan, Metz and Nitzsche",
  },
  {
    id: 56,
    name: "Nelly Pennigar",
    title: "Chemical Engineer",
    email: "npennigar1j@g.co",
    company: "Torphy Group",
  },
  {
    id: 57,
    name: "Maddy Mateev",
    title: "Statistician IV",
    email: "mmateev1k@walmart.com",
    company: "Rowe, Lockman and Carroll",
  },
  {
    id: 58,
    name: "Reginald Forst",
    title: "Editor",
    email: "rforst1l@networksolutions.com",
    company: "Corwin Group",
  },
  {
    id: 59,
    name: "Shari Bosanko",
    title: "VP Quality Control",
    email: "sbosanko1m@xing.com",
    company: "Morar, Moore and Dibbert",
  },
  {
    id: 60,
    name: "Nettie Mattea",
    title: "Systems Administrator I",
    email: "nmattea1n@yellowpages.com",
    company: "Dooley-Kilback",
  },
  {
    id: 61,
    name: "Mikaela Denziloe",
    title: "Recruiter",
    email: "mdenziloe1o@craigslist.org",
    company: "Bradtke-Prohaska",
  },
  {
    id: 62,
    name: "Sollie Valiant",
    title: "Staff Scientist",
    email: "svaliant1p@intel.com",
    company: "Rice LLC",
  },
  {
    id: 63,
    name: "Binny Croisier",
    title: "Research Assistant II",
    email: "bcroisier1q@fema.gov",
    company: "Reichert and Sons",
  },
  {
    id: 64,
    name: "Sofia Kubera",
    title: "Technical Writer",
    email: "skubera1r@w3.org",
    company: "Morissette, Collins and Spinka",
  },
  {
    id: 65,
    name: "Towny Brooks",
    title: "Database Administrator IV",
    email: "tbrooks1s@soundcloud.com",
    company: "Lind, Yundt and Schneider",
  },
  {
    id: 66,
    name: "Donni Coursor",
    title: "Sales Representative",
    email: "dcoursor1t@geocities.com",
    company: "Leuschke Group",
  },
  {
    id: 67,
    name: "Stormie O'Dougherty",
    title: "Analog Circuit Design manager",
    email: "sodougherty1u@marketwatch.com",
    company: "Schaden and Sons",
  },
  {
    id: 68,
    name: "Gualterio Alastair",
    title: "Information Systems Manager",
    email: "galastair1v@samsung.com",
    company: "Pacocha, Haag and Jacobs",
  },
  {
    id: 69,
    name: "Trista Wardhaugh",
    title: "VP Quality Control",
    email: "twardhaugh1w@printfriendly.com",
    company: "Willms, Bernhard and Quigley",
  },
  {
    id: 70,
    name: "Ynes Gusticke",
    title: "Marketing Manager",
    email: "ygusticke1x@paginegialle.it",
    company: "Schoen, Hermiston and Bartoletti",
  },
  {
    id: 71,
    name: "Cariotta Kingsley",
    title: "Structural Analysis Engineer",
    email: "ckingsley1y@ft.com",
    company: "Kunze and Sons",
  },
  {
    id: 72,
    name: "Georgi Staining",
    title: "Editor",
    email: "gstaining1z@livejournal.com",
    company: "Predovic-Mraz",
  },
  {
    id: 73,
    name: "Regan Thickin",
    title: "Software Engineer II",
    email: "rthickin20@sogou.com",
    company: "Wiegand-Hermiston",
  },
  {
    id: 74,
    name: "Caterina Meeking",
    title: "GIS Technical Architect",
    email: "cmeeking21@ycombinator.com",
    company: "Wolff-Bernhard",
  },
  {
    id: 75,
    name: "Silvana Barenskie",
    title: "Senior Editor",
    email: "sbarenskie22@dropbox.com",
    company: "Macejkovic-Prohaska",
  },
  {
    id: 76,
    name: "Johan Crocumbe",
    title: "Account Executive",
    email: "jcrocumbe23@scribd.com",
    company: "Greenholt, Carroll and Kling",
  },
  {
    id: 77,
    name: "Vlad Hollindale",
    title: "Product Engineer",
    email: "vhollindale24@squidoo.com",
    company: "Sauer-Trantow",
  },
  {
    id: 78,
    name: "Kristyn Catonnet",
    title: "Information Systems Manager",
    email: "kcatonnet25@sbwire.com",
    company: "Nader, Schoen and Fritsch",
  },
  {
    id: 79,
    name: "Meier Harbard",
    title: "Associate Professor",
    email: "mharbard26@g.co",
    company: "Hamill-Moen",
  },
  {
    id: 80,
    name: "Pete Sugars",
    title: "Speech Pathologist",
    email: "psugars27@salon.com",
    company: "Renner, Ebert and Marks",
  },
  {
    id: 81,
    name: "Greggory Hove",
    title: "Sales Associate",
    email: "ghove28@multiply.com",
    company: "Hermann-Russel",
  },
  {
    id: 82,
    name: "Brit Rawnsley",
    title: "Quality Control Specialist",
    email: "brawnsley29@tuttocitta.it",
    company: "O'Connell, Wisoky and Mosciski",
  },
  {
    id: 83,
    name: "Nikoletta Harman",
    title: "Help Desk Operator",
    email: "nharman2a@who.int",
    company: "Ernser-Feest",
  },
  {
    id: 84,
    name: "Ivory Neale",
    title: "Administrative Assistant I",
    email: "ineale2b@woothemes.com",
    company: "Jerde, Zieme and Davis",
  },
  {
    id: 85,
    name: "Ambrosius Stouther",
    title: "Compensation Analyst",
    email: "astouther2c@cdbaby.com",
    company: "Kuhic, Bechtelar and Dare",
  },
  {
    id: 86,
    name: "Morganne Leatt",
    title: "Financial Advisor",
    email: "mleatt2d@youtube.com",
    company: "Emard, Jacobson and Cassin",
  },
  {
    id: 87,
    name: "Carmina Blanning",
    title: "Help Desk Operator",
    email: "cblanning2e@admin.ch",
    company: "Kozey-Crist",
  },
  {
    id: 88,
    name: "Shea Ubank",
    title: "Information Systems Manager",
    email: "subank2f@liveinternet.ru",
    company: "Jaskolski Inc",
  },
  {
    id: 89,
    name: "Zachariah Harnwell",
    title: "Internal Auditor",
    email: "zharnwell2g@seattletimes.com",
    company: "Maggio-Legros",
  },
  {
    id: 90,
    name: "Mitchael Bendan",
    title: "Software Consultant",
    email: "mbendan2h@furl.net",
    company: "Schamberger-Walter",
  },
  {
    id: 91,
    name: "Mohandis Sennett",
    title: "Business Systems Development Analyst",
    email: "msennett2i@mit.edu",
    company: "Keeling, Moore and Lueilwitz",
  },
  {
    id: 92,
    name: "Renae Jouanny",
    title: "Software Engineer IV",
    email: "rjouanny2j@hud.gov",
    company: "Weimann LLC",
  },
  {
    id: 93,
    name: "Ky Jime",
    title: "Professor",
    email: "kjime2k@privacy.gov.au",
    company: "Kerluke and Sons",
  },
  {
    id: 94,
    name: "Marika Harrisson",
    title: "Media Manager I",
    email: "mharrisson2l@discuz.net",
    company: "Pollich and Sons",
  },
  {
    id: 95,
    name: "Freida O'Duane",
    title: "Quality Engineer",
    email: "foduane2m@biglobe.ne.jp",
    company: "Corkery-Macejkovic",
  },
  {
    id: 96,
    name: "Hermann Spadeck",
    title: "Executive Secretary",
    email: "hspadeck2n@usda.gov",
    company: "Legros, Hudson and Beahan",
  },
  {
    id: 97,
    name: "Emilie Kirke",
    title: "Associate Professor",
    email: "ekirke2o@tamu.edu",
    company: "Kris-Prohaska",
  },
  {
    id: 98,
    name: "Bebe Baynham",
    title: "Director of Sales",
    email: "bbaynham2p@pinterest.com",
    company: "Rolfson, Ledner and Dickens",
  },
  {
    id: 99,
    name: "Reagan Tortis",
    title: "Junior Executive",
    email: "rtortis2q@nhs.uk",
    company: "McClure-Ankunding",
  },
  {
    id: 100,
    name: "Deck Beinke",
    title: "Clinical Specialist",
    email: "dbeinke2r@4shared.com",
    company: "Romaguera Group",
  },
];

export const counterData = [
  {
    icon: "mdi mdi-home-group",
    color: "text-green-600",
    title: "Total de Imóveis",
    target: "3895",
    symbol: "",
  },
  {
    icon: "mdi mdi-trending-up",
    color: "text-green-600",
    title: "Imóveis Adicionados (últimas 24H)",
    target: "123",
    symbol: "",
  },
  {
    icon: "mdi mdi-trending-down",
    color: "text-red-600",
    title: "Imóveis Removidos (últimas 24H)",
    target: "58",
    symbol: "",
  },
  {
    icon: "mdi mdi-home-lightning-bolt-outline",
    color: "text-green-600",
    title: "Total de Imóveis para Venda",
    target: "3758",
    symbol: "",
  },
  {
    icon: "mdi mdi-home-clock-outline",
    color: "text-green-600",
    title: "Total de Imóveis para Alugar",
    target: "137",
    symbol: "",
  },
];
export const salesData = [
  {
    title: "Apartamento",
    sale: "47.8%",
  },
  {
    title: "Casa",
    sale: "24.7%",
  },
  {
    title: "Lote",
    sale: "10.8%",
  },
  {
    title: "Rural",
    sale: "7.9%",
  },
  {
    title: "Loja",
    sale: "4.1%",
  },
  {
    title: "Outro",
    sale: "1.9%",
  },
];
export const recentTransection = [
  {
    image: property1,
    date: "07 de Outubro de 2024",
    name: "Casa leilão Cor Fabriciano",
    price: "R$ 582.000,00",
    type: "Venda",
    status: "olx",
  },
  {
    image: property2,
    date: "10 de Outubro de 2024",
    name: "Vendo apartamento cidade nobre",
    price: "R$ 395.000,00",
    type: "Venda",
    status: "alleximoveis",
  },
  {
    image: property3,
    date: "15 de Outubro de 2024",
    name: "Vendo troco galpão 580 m2",
    price: "R$ 125.000,00",
    type: "Aluguel",
    status: "olx",
  },
  {
    image: property4,
    date: "20 de Outubro de 2024",
    name: "Apartamento Bairro Iguaçu. Cód. A094. 2 quartos/suíte, 65 m². Perto do comercio e avenida principal",
    price: "R$ 290.000,00",
    type: "Aluguel",
    status: "vivareal",
  },
  {
    image: property5,
    date: "25 de Outubro de 2024",
    name: "Apartamento para venda no Bairro Sta Helena@",
    price: "R$ 250.000,00",
    type: "Venda",
    status: "dommusimoveis",
  },
];

export const topProperties = [
  {
    image: property1,
    name: "House",
    loction: "Baton Rouge, USA",
    rate: "11%",
    status: "down",
  },
  {
    image: property2,
    name: "House",
    loction: "Baton Rouge, USA",
    rate: "20%",
    status: "up",
  },
  {
    image: property3,
    name: "House",
    loction: "Baton Rouge, USA",
    rate: "24%",
    status: "up",
  },
  {
    image: property4,
    name: "House",
    loction: "Baton Rouge, USA",
    rate: "21%",
    status: "up",
  },
  {
    image: property5,
    name: "House",
    loction: "Baton Rouge, USA",
    rate: "45%",
    status: "up",
  },
];

export const propertiesData = [
  {
    id: 1,
    image: property1,
    name: "10765 Hillshire Ave, Baton Rouge, LA 70810, USA",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 2,
    image: property2,
    name: "59345 STONEWALL DR, Plaquemine, LA 70764, USA",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 3,
    image: property3,
    name: "3723 SANDBAR DR, Addis, LA 70710, USA",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 4,
    image: property4,
    name: "Lot 21 ROYAL OAK DR, Prairieville, LA 70769, USA",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 5,
    image: property5,
    name: "710 BOYD DR, Unit #1102, Baton Rouge, LA 70808, USA",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 6,
    image: property6,
    name: "5133 MCLAIN WAY, Baton Rouge, LA 70809, USA",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 7,
    image: property7,
    name: "2141 Fiero Street, Baton Rouge, LA 70808",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 8,
    image: property8,
    name: "9714 Inniswold Estates Ave, Baton Rouge, LA 70809",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 9,
    image: property9,
    name: "1433 Beckenham Dr, Baton Rouge, LA 70808, USA",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 10,
    image: property10,
    name: "1574 Sharlo Ave, Baton Rouge, LA 70820, USA",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 11,
    image: property11,
    name: "2528 BOCAGE LAKE DR, Baton Rouge, LA 70809, USA",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
  {
    id: 12,
    image: property12,
    name: "1533 NICHOLSON DR, Baton Rouge, LA 70802, USA",
    sqf: "8000sqf",
    beds: "4 Beds",
    baths: "4 Baths",
    value: "$5000",
  },
];

export const chatData = [
  {
    image: client7,
    name: "Christopher",
    msg: "Hello",
    time: "10 Min",
    status: "online",
    unReadMsg: "",
    bg: "true",
  },
  {
    image: client1,
    name: "Dr. Cristino",
    msg: "Hi, How are you?",
    time: "20 Min",
    status: "offline",
    unReadMsg: "2",
    bg: "false",
  },
  {
    image: client3,
    name: "Faye",
    msg: "Heyy",
    time: "30 Min",
    status: "offline",
    unReadMsg: "",
    bg: "false",
  },
  {
    image: client4,
    name: "Ronald",
    msg: "Hey, How are you sir?",
    time: "2 Hours",
    status: "offline",
    unReadMsg: "",
    bg: "false",
  },
  {
    image: client11,
    name: "Melissa",
    msg: "Good Afternoon",
    time: "3 Hours",
    status: "online",
    unReadMsg: "",
    bg: "false",
  },
  {
    image: client12,
    name: "Elsie",
    msg: "Good Morning sir, how can i help you?",
    time: "10 Hours",
    status: "online",
    unReadMsg: "",
    bg: "false",
  },
  {
    image: client7,
    name: "Jerry",
    msg: "Please give me appointment",
    time: "16 Hours",
    status: "online",
    unReadMsg: "",
    bg: "false",
  },
  {
    image: client13,
    name: "Louis",
    msg: "Hii",
    time: "1 Days",
    status: "offline",
    unReadMsg: "",
    bg: "false",
  },
  {
    image: client6,
    name: "Randall",
    msg: "Hello Sir",
    time: "2 Days",
    status: "offline",
    unReadMsg: "",
    bg: "false",
  },
  {
    image: client10,
    name: "Mary",
    msg: "How are you sir?",
    time: "3 Days",
    status: "offline",
    unReadMsg: "",
    bg: "false",
  },
  {
    image: client8,
    name: "Lester",
    msg: "Hello please give me answer.",
    time: "4 Days",
    status: "online",
    unReadMsg: "",
    bg: "false",
  },
];

export const blogData = [
  {
    id: 1,
    image: property1,
    title: "Skills That You Can Learn In The Real Estate Market",
    date: "13th Sep 2023",
    time: "5 min read",
    tag: "Residential",
  },
  {
    id: 2,
    image: property2,
    title: "Learn The Truth About Real Estate Industry",
    date: "29th Nov 2023",
    time: "5 min read",
    tag: "Land",
  },
  {
    id: 3,
    image: property3,
    title: "10 Quick Tips About Business Development",
    date: "29th Dec 2023",
    time: "5 min read",
    tag: "Commercial",
  },
  {
    id: 4,
    image: property4,
    title: "14 Common Misconceptions About Business Development",
    date: "13th March 2023",
    time: "5 min read",
    tag: "Industrial",
  },
  {
    id: 5,
    image: property5,
    title: "10 Things Your Competitors Can Teach You About Real Estate",
    date: "5th May 2023",
    time: "5 min read",
    tag: "Investment",
  },
  {
    id: 6,
    image: property6,
    title: "Why We Love Real Estate",
    date: "19th June 2023",
    time: "5 min read",
    tag: "Residential",
  },
  {
    id: 7,
    image: property7,
    title: "110 Quick Tips About Real Estate",
    date: "20th June 2023",
    time: "5 min read",
    tag: "Land",
  },
  {
    id: 8,
    image: property8,
    title: "15 Best Blogs To Follow About Real Estate",
    date: "31st Aug 2023",
    time: "5 min read",
    tag: "Commercial",
  },
];
export const recentPost = [
  {
    image: property6,
    title: "10 Things You About Real Estate",
    date: "13th March 2023",
  },
  {
    image: property7,
    title: "Why We Love Real Estate",
    date: "5th May 2023",
  },
  {
    image: property8,
    title: "110 Quick Tips About Real Estate",
    date: "19th June 2023",
  },
];

export const accordianData = [
  {
    title: "How does it work ?",
    desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
  },
  {
    title: "Do I need a designer to use Hously ?",
    desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
  },
  {
    title: "What do I need to do to start selling ?",
    desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
  },
  {
    title: "What happens when I receive an order ?",
    desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
  },
];

export const pricingData = [
  {
    icon: "mdi mdi-tree-outline",
    title: "Basic",
    amount: "19",
    feature: [
      "Full Access",
      "Source Files",
      "Free Appointments",
      "Enhanced Security",
    ],
  },
  {
    icon: "mdi mdi-shield-star-outline",
    title: "Premium",
    amount: "39",
    feature: [
      "Full Access",
      "Source Files",
      "Free Appointments",
      "Enhanced Security",
    ],
  },
  {
    icon: "mdi mdi-rocket-launch-outline",
    title: "Business",
    amount: "99",
    feature: [
      "Full Access",
      "Source Files",
      "Free Appointments",
      "Enhanced Security",
    ],
  },
];
export const reviewData = [
  {
    image: client1,
    name: "Thomas Israel",
    title: "Student",
    desc: "I didn't know a thing about icon design until I read this book. Now I can create any icon I need in no time. Great resource!",
  },
  {
    image: client2,
    name: "Barbara McIntosh",
    title: "Student",
    desc: "There are so many things I had to do with my old software that I just don't do at all with Hously. Suspicious but I can't say I don't love it.",
  },
  {
    image: client3,
    name: "Carl Oliver",
    title: "Student",
    desc: "The best part about Hously is every time I pay my employees, my bank balance doesn't go down like it used to. Looking forward to spending this extra cash when I figure out why my card is being declined.",
  },
  {
    image: client4,
    name: "Jill Webb",
    title: "Student",
    desc: "I'm trying to get a hold of someone in support, I'm in a lot of trouble right now and they are saying it has something to do with my books. Please get back to me right away.",
  },
  {
    image: client5,
    name: "Barbara McIntosh",
    title: "Student",
    desc: "I used to have to remit tax to the EU and with Hously I somehow don't have to do that anymore. Nervous to travel there now though.",
  },
  {
    image: client6,
    name: "Janisha Doll",
    title: "Student",
    desc: "This is the fourth email I've sent to your support team. I am literally being held in jail for tax fraud. Please answer your damn emails, this is important.",
  },
  {
    image: client7,
    name: "Thomas Israel",
    title: "Student",
    desc: "I didn't know a thing about icon design until I read this book. Now I can create any icon I need in no time. Great resource!",
  },
  {
    image: client8,
    name: "Barbara McIntosh",
    title: "Student",
    desc: "There are so many things I had to do with my old software that I just don't do at all with Hously. Suspicious but I can't say I don't love it.",
  },
  {
    image: client9,
    name: "Carl Oliver",
    title: "Student",
    desc: "The best part about Hously is every time I pay my employees, my bank balance doesn't go down like it used to. Looking forward to spending this extra cash when I figure out why my card is being declined.",
  },
  {
    image: client10,
    name: "Jill Webb",
    title: "Student",
    desc: "I'm trying to get a hold of someone in support, I'm in a lot of trouble right now and they are saying it has something to do with my books. Please get back to me right away.",
  },
  {
    image: client11,
    name: "Barbara McIntosh",
    title: "Student",
    desc: "I used to have to remit tax to the EU and with Hously I somehow don't have to do that anymore. Nervous to travel there now though",
  },
  {
    image: client12,
    name: "Janisha Doll",
    title: "Student",
    desc: "This is the fourth email I've sent to your support team. I am literally being held in jail for tax fraud. Please answer your damn emails, this is important.",
  },
  {
    image: client13,
    name: "Thomas Israel",
    title: "Student",
    desc: "I didn't know a thing about icon design until I read this book. Now I can create any icon I need in no time. Great resource!",
  },
  {
    image: client1,
    name: "Barbara McIntosh",
    title: "Student",
    desc: "There are so many things I had to do with my old software that I just don't do at all with Hously. Suspicious but I can't say I don't love it.",
  },
  {
    image: client2,
    name: "Carl Oliver",
    title: "Student",
    desc: "The best part about Hously is every time I pay my employees, my bank balance doesn't go down like it used to. Looking forward to spending this extra cash when I figure out why my card is being declined.",
  },
];
