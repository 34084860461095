import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const DistributionByNeighborhood = ({ stats }) => {
  const [series, setSeries] = useState([]);
  
  useEffect(() => {
    if(stats) {
        let data = stats.count.by_neighborhood_top10.map(item => {
            return {x: item["_neighborhood"], y: item["_counter"]}
        })
        setSeries([{data}]);
    }
  }, [stats]);

  let options = {
    chart: {
      height: 360,
      type: "treemap",
      width: "100%",
      stacked: true,
      toolbar: {
        show: false,
        autoSelected: "zoom",
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      strokeDashArray: 3,
    },
    plotOptions: {
      treemap: {
        distributed: false,
      },
    },
  };

  return (
    <div className="lg:col-span-8">
      <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
        <div className="p-6 flex items-center justify-between border-b border-gray-100 dark:border-gray-800">
          <h6 className="text-lg font-semibold">
            Distribuição de imóveis por bairro
          </h6>

          <div className="position-relative">
            <select
              className="form-select form-input w-full py-2 h-10 bg-white dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-gray-200 dark:border-gray-800 dark:focus:border-gray-700 focus:ring-0"
              id="yearchart"
            >
              <option defaultValue="T">Hoje</option>
              <option defaultValue="M">7 Dias atrás</option>
              <option defaultValue="W">30 Dias atrás</option>
            </select>
          </div>
        </div>
        <Chart
          options={options}
          series={series}
          type={options.chart.type}
          width="100%"
          height={360}
          className="apex-chart px-4 pb-6"
        />
      </div>
    </div>
  );
};

export default DistributionByNeighborhood;