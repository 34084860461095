import React from "react";
import { Link } from "react-router-dom";
import Switcher from "../components/switcher";

export default function SignupSuccess() {
  return (
    <>
      <section className="relative h-screen flex justify-center items-center bg-slate-50 dark:bg-slate-800">
        <div className="container">
          <div className="md:flex justify-center">
            <div className="lg:w-2/5">
              <div className="relative overflow-hidden rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800">
                <div className="px-6 py-12 bg-green-600 text-center">
                  <i className="mdi mdi-check-circle-outline text-white text-8xl"></i>
                  <h5 className="text-white text-xl tracking-wide uppercase font-semibold mt-2">
                    Success
                  </h5>
                </div>

                <div className="px-6 py-12 text-center">
                  <p className="text-black font-semibold text-xl dark:text-white">
                    Congratulations! 🎉
                  </p>
                  <p className="text-slate-400 mt-4">
                    Your account has been successfully created. <br /> Enjoy
                    your journey. Thank you
                  </p>

                  <div className="mt-6">
                    <Link
                      to="/index"
                      className="btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-greenbg-green-700 text-white rounded-md"
                    >
                      Continue
                    </Link>
                  </div>
                </div>

                <div className="text-center p-6 border-t border-gray-100 dark:border-gray-700">
                  <p className="mb-0 text-slate-400 font-medium">
                    © {new Date().getFullYear()} Hously. Design with{" "}
                    <i className="mdi mdi-heart text-red-600"></i> by{" "}
                    <Link
                      to="https://shreethemes.in/"
                      target="_blank"
                      className="text-reset"
                    >
                      Shreethemes
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Switcher />
    </>
  );
}
