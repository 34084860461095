import { createContext, useState } from "react";
import { supabase } from "../services/supabase";
import { v4 as uuidv4 } from "uuid";

export const UserProfileContext = createContext({});

export const UserProfileProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [userFavorites, setUserFavorites] = useState([]);

  const uploadAvatar = async (user_id, file) => {
    const { data, error } = await supabase.storage
      .from("avatars")
      .upload(user_id + "/" + uuidv4(), file);
    if (error) {
      console.error("Error uploading avatar:", error.message);
      throw error;
    }
    return data;
  };

  const fetchUserData = async () => {
    const { data, error } = await supabase
      .from("user_profile")
      .select()
      .single();
    if (error) {
      console.error("Error fetching user data:", error.message);
      throw error;
    }
    setUserData(data);
  };

  const updateUserData = async (user_id, data) => {
    const { error } = await supabase
      .from("user_profile")
      .update(data)
      .eq("user_id", user_id);

    if (error) {
      console.error("Error updating user data:", error.message);
      throw error;
    }
    return true;
  };

  const fetchUserFavorites = async () => {
    const { data, error } = await supabase
      .from("user_profile")
      .select("favorites")
      .single();
    if (error) {
      console.error("Error fetching user favorites:", error.message);
      throw error;
    }
    setUserFavorites(data.favorites);
  };

  // remove user favorite
  const removeUserFavorite = async (user_id, property_id) => {
    const { data, error } = await supabase
      .from("user_profile")
      .update({
        favorites: userFavorites.filter((id) => id !== property_id),
      })
      .eq("user_id", user_id)
      .single();
    if (error) {
      console.error("Error removing user favorite:", error.message);
      throw error;
    }
    setUserFavorites(userFavorites.filter((id) => id !== property_id));
  };

  // insert user favorite
  const insertUserFavorite = async (user_id, property_id) => {
    const { data, error } = await supabase
      .from("user_profile")
      .update({
        favorites: [...userFavorites, property_id],
      })
      .eq("user_id", user_id)
      .single();
    if (error) {
      console.error("Error inserting user favorite:", error.message);
      throw error;
    }
    setUserFavorites([...userFavorites, property_id]);
  };

  return (
    <UserProfileContext.Provider
      value={{
        userData,
        fetchUserData,
        updateUserData,
        uploadAvatar,
        userFavorites,
        fetchUserFavorites,
        removeUserFavorite,
        insertUserFavorite,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
