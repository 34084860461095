import { useState, useRef, useContext } from "react";

import { AiSearchContext } from "../../context/aisearch";
import Menu from "./menu";
import PropertyGrid from "./property-grid";
import PropertyGridSkeleton from "./property-grid-skeleton";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const FormWeb = ({ searchRef, loading, handleSubmit }) => (
  <form className="hidden md:block">
    <input
      type="text"
      className="w-full py-4 pl-6 pr-40 rounded-full bg-white dark:bg-slate-600 dark:text-white text-gray-800 text-lg shadow-md focus:outline-none focus:ring-4 focus:ring-green-500"
      placeholder="Ex. 'Apartamento com 3 quartos no bairro Centro'"
      ref={searchRef}
      onSubmit={loading ? null : handleSubmit}
    />
    <button
      className="absolute right-2 top-[0.4rem] bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded-full font-semibold transition-all"
      onClick={loading ? null : handleSubmit}
    >
      Buscar com IA
    </button>
  </form>
);

const FormMobile = ({ searchRef, loading, handleSubmit }) => (
  <form className="md:hidden" onSubmit={loading ? null : handleSubmit}>
    <input
      type="text"
      className="w-full py-4 pl-6 pr-20 rounded-md bg-white dark:bg-slate-600 dark:text-white text-gray-800 text-sm shadow-md focus:outline-none focus:ring-4 focus:ring-green-500"
      placeholder="Ex. 'Casa com 3 quartos'"
      ref={searchRef}
    />

    <button
      className="absolute right-[0.2rem] top-[0.25rem] bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded-md font-semibold transition-all"
      onClick={loading ? null : handleSubmit}
    >
      <MagnifyingGlassIcon aria-hidden="true" className="size-5" />
    </button>
  </form>
);

const SearchComponent = ({ searchRef, handleSubmit, loading }) => (
  <div className="flex mt-6 justify-center">
    <div className="max-w-full w-full md:mx-96 p-8 rounded-lg text-center">
      <div className="relative">
        {/* -------- DESKTOP --------- */}
        <FormWeb
          handleSubmit={handleSubmit}
          searchRef={searchRef}
          loading={loading}
        />
        {/* -------- MOBILE --------- */}
        <FormMobile
          handleSubmit={handleSubmit}
          searchRef={searchRef}
          loading={loading}
        />
      </div>
    </div>
  </div>
);

export default function AiSearch() {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const { searchProperties } = useContext(AiSearchContext);

  const searchRef = useRef();

  const handleSearch = async () => {
    const userQuery = searchRef.current.value;

    setLoading(true);

    const response = await searchProperties(userQuery);
    setResults(response);

    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <Menu />
        {loading ? (
          <>
            <SearchComponent
              searchRef={searchRef}
              handleSubmit={handleSubmit}
              loading={loading}
            />
            <PropertyGridSkeleton />
          </>
        ) : (
          <>
            <SearchComponent
              searchRef={searchRef}
              handleSubmit={handleSubmit}
              loading={loading}
            />
            <PropertyGrid posts={results} />
          </>
        )}
      </div>
    </div>
  );
}
