import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegAddressCard, FaWhatsapp } from "react-icons/fa6";
import {
  FiGlobe,
  FiMail,
  FiPhone,
  FiPaperclip,
  FiUser,
  FiInfo,
  FiLock,
} from "react-icons/fi";

import banner from "../../assets/images/jason-dent-w3eFhqXjkZE-unsplash.jpg";

import { UserProfileContext } from "../../context/user";

export default function ProfileSetting() {
  const navigate = useNavigate();
  const { userData, fetchUserData, updateUserData, uploadAvatar } =
    useContext(UserProfileContext);

  // form refs
  const inputName = useRef(null);
  const inputEmail = useRef(null);
  const inputAccountType = useRef(null);
  const inputDocument = useRef(null);
  const inputPhone = useRef(null);
  const inputWhatsapp = useRef(null);
  const inputZipcode = useRef(null);
  const inputStreet = useRef(null);
  const inputNumber = useRef(null);
  const inputState = useRef(null);
  const inputCity = useRef(null);
  const inputNeighborhood = useRef(null);
  const inputZone = useRef(null);
  const [avatarUrl, setAvatarUrl] = useState(null);

  function handleChange(e) {
    let file = e.target.files[0];

    uploadAvatar(userData.user_id, file)
      .then((data) => {
        setAvatarUrl(
          `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${data.fullPath}`,
        );
      })
      .catch((_error) => {
        alert("Failed to upload avatar");
        return;
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    updateUserData(userData.user_id, {
      name: inputName.current.value,
      email: inputEmail.current.value,
      account_type: inputAccountType.current.value,
      document: inputDocument.current.value,
      phone: inputPhone.current.value,
      whatsapp: inputWhatsapp.current.value,
      zipcode: inputZipcode.current.value,
      street: inputStreet.current.value,
      number: inputNumber.current.value,
      state: inputState.current.value,
      city: inputCity.current.value,
      neighborhood: inputNeighborhood.current.value,
      zone: inputZone.current.value,
      avatar_url: avatarUrl || userData.avatar_url,
    })
      .then((data) => {
        console.log("User data updated successfully");
        console.log(data);
        navigate("/profile");
      })
      .catch((error) => {
        alert("Failed to update user data");
        return;
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (!userData) return <h1>Loading..</h1>;

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="grid grid-cols-1">
          <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
            <div className="relative shrink-0">
              <img
                src={banner}
                className="h-80 w-full object-cover"
                id="profile-banner"
                alt=""
              />
              <div className="absolute inset-0 bg-black/70"></div>
              <label className="absolute inset-0" htmlFor="pro-banner"></label>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1 gap-6 mt-6">
          <div className="xl:col-span-3 lg:col-span-4 md:col-span-4">
            <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
              <div className="profile-pic text-center">
                <input
                  id="pro-img"
                  name="profile-image"
                  type="file"
                  className="hidden"
                  onChange={handleChange}
                />
                <div>
                  <div className="relative size-40 mx-auto">
                    <img
                      src={avatarUrl || userData.avatar_url}
                      className="shadow rounded-xl dark:shadow-gray-700 ring-4 ring-slate-50 dark:ring-slate-800"
                      id="profile-image"
                      alt="profile image"
                      width={200}
                      height={200}
                    />
                    <label
                      className="absolute inset-0 cursor-pointer"
                      htmlFor="pro-img"
                    ></label>
                  </div>

                  <div className="mt-24">
                    <h5 className="text-lg font-semibold">{userData.name}</h5>
                    <p className="text-slate-400">{userData.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:col-span-9 lg:col-span-8 md:col-span-8">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-6">
                {/* SECTION: Informações Pessoais */}
                <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                  <h5 className="text-lg font-semibold mb-4">
                    Informações Pessoais:{" "}
                  </h5>

                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-5">
                    <div className="col-span-2">
                      <label className="form-label font-medium">
                        Seu Nome: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiUser className="size-4 absolute top-3 start-4" />
                        <input
                          type="text"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0"
                          placeholder="Nome:"
                          id="name"
                          name="name"
                          required={true}
                          defaultValue={userData.name}
                          ref={inputName}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label className="form-label font-medium">
                        Tipo de conta: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiPaperclip className="size-4 absolute top-3 start-4" />
                        <select
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0"
                          name="account_type"
                          id="account_type"
                          required={true}
                          defaultValue={userData.account_type}
                          ref={inputAccountType}
                        >
                          <option value="individual">Pessoa Física</option>
                          <option value="company">Pessoa Jurídica</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label className="form-label font-medium">
                        Documento: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FaRegAddressCard className="size-4 absolute top-3 start-4" />
                        <input
                          type="text"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0"
                          placeholder="Documento: (CPF/CNPJ)"
                          id="document"
                          name="document"
                          required={true}
                          defaultValue={userData.document}
                          ref={inputDocument}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* SECTION: Informações de Contato */}
                <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                  <h5 className="text-lg font-semibold mb-4">
                    Informações de Contato:{" "}
                  </h5>

                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-5">
                    <div className="col-start-1 col-end-2">
                      <label className="form-label font-medium">
                        E-mail: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiMail className="size-4 absolute top-3 start-4" />
                        <input
                          type="email"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0 disabled:text-gray-500"
                          placeholder="E-mail"
                          name="email"
                          id="email"
                          disabled={true}
                          defaultValue={userData.email}
                          ref={inputEmail}
                        />
                      </div>
                    </div>
                    <div className="col-start-1 col-end-2">
                      <label className="form-label font-medium">
                        Telefone/Celular:{" "}
                        <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiPhone className="size-4 absolute top-3 start-4" />
                        <input
                          type="tel"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0 disabled:text-gray-500"
                          placeholder="Telefone"
                          name="phone"
                          id="phone"
                          defaultValue={userData.phone}
                          ref={inputPhone}
                        />
                      </div>
                    </div>
                    <div className="col-start-1 col-end-2">
                      <label className="form-label font-medium">
                        Whatsapp: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FaWhatsapp className="size-4 absolute top-3 start-4" />
                        <input
                          type="text"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0 disabled:text-gray-500"
                          placeholder="+55 ()"
                          name="whatsapp"
                          id="whatsapp"
                          defaultValue={userData.whatsapp}
                          ref={inputWhatsapp}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* SECTION: Endereço */}
                <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                  <h5 className="text-lg font-semibold mb-4">Endereço: </h5>

                  <div className="grid lg:grid-cols-4 sm:grid-cols-1 gap-5">
                    <div className="col-start-1 col-end-1">
                      <label className="form-label font-medium">
                        CEP: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiInfo className="size-4 absolute top-3 start-4" />
                        <input
                          type="text"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0 disabled:text-gray-500"
                          placeholder="00000-000"
                          name="zipcode"
                          id="zipcode"
                          defaultValue={userData.zipcode}
                          ref={inputZipcode}
                        />
                      </div>
                    </div>
                    <div className="col-start-1 col-end-4">
                      <label className="form-label font-medium">
                        Rua: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiInfo className="size-4 absolute top-3 start-4" />
                        <input
                          type="text"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0 disabled:text-gray-500"
                          placeholder="Rua/Logadouro/Avenida"
                          name="street"
                          id="street"
                          defaultValue={userData.street}
                          ref={inputStreet}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label className="form-label font-medium">
                        Número: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiInfo className="size-4 absolute top-3 start-4" />
                        <input
                          type="number"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0 disabled:text-gray-500"
                          placeholder=""
                          name="number"
                          id="number"
                          defaultValue={userData.number}
                          ref={inputNumber}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label className="form-label font-medium">
                        Estado: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiInfo className="size-4 absolute top-3 start-4" />
                        <select
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0"
                          name="state"
                          id="state"
                          defaultValue={userData.state}
                          ref={inputState}
                        >
                          <option value="MG">MG</option>
                          <option value="SP">SP</option>
                          <option value="RJ">RJ</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label className="form-label font-medium">
                        Cidade: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiInfo className="size-4 absolute top-3 start-4" />
                        <select
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0"
                          name="city"
                          id="city"
                          defaultValue={userData.city}
                          ref={inputCity}
                        >
                          <option value="Ipatinga">Ipatinga</option>
                          <option value="Guarulhos">Guarulhos</option>
                          <option value="Niteroi">Niteroi</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <label className="form-label font-medium">
                        Bairro: <span className="text-red-600">*</span>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiInfo className="size-4 absolute top-3 start-4" />
                        <input
                          type="text"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0 disabled:text-gray-500"
                          placeholder="Bairro"
                          name="neighborhood"
                          id="neighborhood"
                          defaultValue={userData.neighborhood}
                          ref={inputNeighborhood}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* SECTION: Acesso */}
                <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                  <h5 className="text-lg font-semibold mb-4">Acesso:</h5>
                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-5">
                    <div className="col-start-1 col-end-2">
                      <label className="form-label font-medium">
                        Senha:{" "}
                        <small className="text-red-600">
                          (Entre em contato caso queira alterar sua senha)
                        </small>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiLock className="size-4 absolute top-3 start-4" />
                        <input
                          type="password"
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0 disabled:text-gray-500"
                          placeholder="*****"
                          name="new-password"
                          id="new-password"
                          autoComplete="new-password"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label className="form-label font-medium">
                        Zona de acesso:{" "}
                        <small className="text-red-600">
                          (Entre em contato caso queira alterar sua zona de
                          acesso)
                        </small>
                      </label>
                      <div className="form-icon relative mt-2">
                        <FiGlobe className="size-4 absolute top-3 start-4" />
                        <select
                          className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-green-600 dark:border-gray-800 dark:focus:border-green-600 focus:ring-0"
                          name="zone"
                          id="zone"
                          disabled={true}
                          defaultValue={userData.zone}
                          ref={inputZone}
                        >
                          <option value="VDA">Vale do Aço</option>
                          <option value="BH">Belo Horizone</option>
                          <option value="SP">São Paulo</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* SECTION: Salvar alterações */}
                <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                  <h5 className="text-lg font-semibold mb-4 text-green-600">
                    Salvar alterações :
                  </h5>

                  <p className="text-slate-400 mb-4">
                    Clique no botão abaixo para salvar as alterações feitas no
                    seu perfil.
                  </p>

                  <input
                    type="submit"
                    id="submit"
                    name="submit"
                    className="btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md mt-5 hover:cursor-pointer"
                    value="Salvar"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
