const sort_data = (data) => {
    let sortable = [];
    for (var item in data) {
        sortable.push([item, data[item]]);
    }
    
    sortable.sort(function(a, b) {
        return a[1] - b[1];
    });

    let ascending_order = sortable;                     // ordem crescente
    let descending_order = sortable.reverse();          // ordem decrescente
    let descending_order_top_6 = ascending_order.slice(0, 6);    // top 6 ordem crescente
    let ascending_order_top_6 = descending_order.slice(0, 6);   // top 6 ordem decrescente

    // retorna o top 6 em ordem decrescente
    return descending_order_top_6;
}

export const DistributionByType = ({ stats }) => {
  return (
    <div className="lg:col-span-4">
      <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
        <div className="p-6 flex items-center justify-between border-b border-gray-100 dark:border-gray-800">
          <h6 className="text-lg font-semibold">
            Proporção de imóveis por tipo
          </h6>

          <div className="position-relative">
            <select
              className="form-select form-input w-full py-2 h-10 bg-white dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-gray-200 dark:border-gray-800 dark:focus:border-gray-700 focus:ring-0"
              id="yearchart"
            >
              <option defaultValue="T">Hoje</option>
              <option defaultValue="M">7 Dias atrás</option>
              <option defaultValue="W">30 Dias atrás</option>
            </select>
          </div>
        </div>

        <div className="p-6">
          {sort_data(stats.percentage.by_type).map((item, index) => {
            return (
              <div className="mt-5 first:mt-0" key={index}>
                <div className="flex justify-between mb-2">
                  <span className="text-slate-400">{item[0]}</span>
                  <span className="text-slate-400">{item[1]}%</span>
                </div>
                <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                  <div
                    className="bg-green-600 h-[6px] rounded-full"
                    style={{ width: `${item[1]}%` }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DistributionByType;