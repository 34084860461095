import { createContext, useState } from "react";
import { supabase } from "../services/supabase";

export const PropertyListingContext = createContext({});

export const PropertyListingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [postsCount, setPostsCount] = useState(0);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [filters, setFilters] = useState([]);

  // obtem a quantidade de posts
  const fetchPostsCount = async (filters = undefined) => {
    let query = supabase
      .from("data")
      .select("*", { count: "exact", head: true });

    // aplica os filtros caso sejam informados
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        query = query.filter(filter.column, filter.operator, filter.value);
      });
    }

    const { count } = await query;
    setPostsCount(count);
  };

  // obtem os posts
  const fetchPosts = async (filters = undefined) => {
    // comeca com um valor padrao caso esteja na primeira pagina
    let from = 0;
    let to = postsPerPage - 1;

    // realiza os devidos calculos caso esteja em outras paginas
    if (currentPage !== 1) {
      from = currentPage * postsPerPage;
      to = from + postsPerPage - 1;
    }

    let query = supabase.from("data").select("*").range(from, to);

    // aplica os filtros caso sejam informados
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        query = query.filter(filter.column, filter.operator, filter.value);
      });
    }

    const { data } = await query;
    setPosts(data);
  };

  return (
    <PropertyListingContext.Provider
      value={{
        loading,
        setLoading,
        postsCount,
        setPostsCount,
        posts,
        setPosts,
        currentPage,
        setCurrentPage,
        postsPerPage,
        setPostsPerPage,
        fetchPostsCount,
        fetchPosts,
        filters,
        setFilters,
      }}
    >
      {children}
    </PropertyListingContext.Provider>
  );
};
