import CountUp from "react-countup";

export const GeneralStats = ({ stats }) => {
    return (
        <>
            {/* Total de Imóveis */}
            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div className="p-5 flex items-center justify-between">
                <span className="me-3">
                    <span className="text-slate-400 block">Total de Imóveis</span>
                    <span className="flex items-center justify-between mt-1">
                    <span className="text-2xl font-medium">
                        <CountUp start={0} end={stats.total_properties} />
                    </span>
                    </span>
                </span>
    
                <span
                    className={`flex justify-center items-center rounded-md size-12 min-w-[48px] bg-slate-50 dark:bg-slate-800 shadow shadow-gray-100 dark:shadow-gray-700 text-green-600`}
                >
                    <i className={`mdi mdi-home-group text-[28px]`}></i>
                </span>
                </div>
            </div>
            {/* Imóveis Adicionados (últimas 24H) */}
            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div className="p-5 flex items-center justify-between">
                <span className="me-3">
                    <span className="text-slate-400 block">Imóveis Adicionados (últimas 24H)</span>
                    <span className="flex items-center justify-between mt-1">
                    <span className="text-2xl font-medium">
                        <CountUp start={0} end={stats.total_properties_added_24h || 15} />
                    </span>
                    </span>
                </span>
    
                <span
                    className={`flex justify-center items-center rounded-md size-12 min-w-[48px] bg-slate-50 dark:bg-slate-800 shadow shadow-gray-100 dark:shadow-gray-700 text-green-600`}
                >
                    <i className={`mdi mdi-trending-up text-[28px]`}></i>
                </span>
                </div>
            </div>
            {/* Imóveis Removidos (últimas 24H) */}
            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div className="p-5 flex items-center justify-between">
                <span className="me-3">
                    <span className="text-slate-400 block">Imóveis Removidos (últimas 24H)</span>
                    <span className="flex items-center justify-between mt-1">
                    <span className="text-2xl font-medium">
                        <CountUp start={0} end={stats.total_properties_removed_24h || 10} />
                    </span>
                    </span>
                </span>
    
                <span
                    className={`flex justify-center items-center rounded-md size-12 min-w-[48px] bg-slate-50 dark:bg-slate-800 shadow shadow-gray-100 dark:shadow-gray-700 text-green-600`}
                >
                    <i className={`mdi mdi-trending-down text-[28px]`}></i>
                </span>
                </div>
            </div>
            {/* Total de Imóveis para Venda */}
            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div className="p-5 flex items-center justify-between">
                <span className="me-3">
                    <span className="text-slate-400 block">Total de Imóveis para Venda</span>
                    <span className="flex items-center justify-between mt-1">
                    <span className="text-2xl font-medium">
                        <CountUp start={0} end={stats.count.by_deal_type.sale} />
                    </span>
                    </span>
                </span>
    
                <span
                    className={`flex justify-center items-center rounded-md size-12 min-w-[48px] bg-slate-50 dark:bg-slate-800 shadow shadow-gray-100 dark:shadow-gray-700 text-green-600`}
                >
                    <i className={`mdi mdi-home-lightning-bolt-outline text-[28px]`}></i>
                </span>
                </div>
            </div>
            {/* Total de Imóveis para Alugar */}
            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                <div className="p-5 flex items-center justify-between">
                <span className="me-3">
                    <span className="text-slate-400 block">Total de Imóveis para Alugar</span>
                    <span className="flex items-center justify-between mt-1">
                    <span className="text-2xl font-medium">
                        <CountUp start={0} end={stats.count.by_deal_type.rent} />
                    </span>
                    </span>
                </span>
    
                <span
                    className={`flex justify-center items-center rounded-md size-12 min-w-[48px] bg-slate-50 dark:bg-slate-800 shadow shadow-gray-100 dark:shadow-gray-700 text-green-600`}
                >
                    <i className={`mdi mdi-home-clock-outline text-[28px]`}></i>
                </span>
                </div>
            </div>
        </>
    )
};

export default GeneralStats;
