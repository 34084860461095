import * as Icons from "react-icons/fi";

/**
 * Rendereiza um ícone do pacote `react-icons/fi` com base no nome. \
 * ref: https://stackoverflow.com/a/65576819
 */
export const Fi = ({ name, className }) => {
  const IconComponent = Icons[name];
  const _className = className || "";

  if (!IconComponent) {
    // Return a default one
    return <Icons.FiXOctagon className={_className} />;
  }

  return <IconComponent className={_className} />;
};
