import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Pagination({ loading, postsCount, currentPage, postsPerPage, setCurrentPage }) {
  const [pageNumbers, setPageNumbers] = useState([]);

  const RightShift = () => {
    let copy = pageNumbers;

    // verifica se ja chegou na ultima pagina
    if(pageNumbers.slice(0,9).pop() == Math.ceil(postsCount / postsPerPage))
      return;

    // remove o primeiro item do indice
    copy.shift();

    // adiciona um novo indice ao array
    let val1 = copy[copy.length - 1];
    let val2 = val1 += 1;

    copy.push(val2);
    setPageNumbers(copy);
  }

  const LeftShift = () => {
    // verifica se da pra deslocar mais pra esquerda
    if(pageNumbers[0] === 1)
      return;
    
    let copy = pageNumbers;

    // remove o ultimo item do indice
    copy.pop();

    // adiciona um novo indice no inicio do array
    let val1 = copy[0];
    let val2 = val1 -= 1;

    copy.unshift(val2);

    // altera as paginas
    setPageNumbers(copy);
  }

  useEffect(() => {
    let counter = [];

    for (let i = 1; i <= Math.ceil(postsCount / postsPerPage); i++) {
      counter.push(i);
    }
    setPageNumbers(counter);
  }, [postsCount, postsPerPage]);

  
  if(loading || postsCount === 0 || pageNumbers.length === 0) {
    return <h2 variant="h2"> Loading.. </h2>
  }

  return (
    <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
      <div className="md:col-span-12 text-center">
        <nav>
          <ul className="inline-flex items-center -space-x-px">
            <li>
              <Link
                to="#"
                className="size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"
                onClick={LeftShift}
              >
                <i className="mdi mdi-chevron-left text-[20px]"></i>
              </Link>
            </li>
            {
              pageNumbers.slice(0,9).map(n => (
                <li key={n}>
                  <Link 
                    to="#" 
                    aria-current={
                      n === currentPage 
                      ? "page"
                      : "foo"
                    }
                    className={
                      n === currentPage 
                      ? "z-10 size-10 inline-flex justify-center items-center mx-1 rounded-full text-white bg-green-600 shadow-sm dark:shadow-gray-700"
                      : "size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"
                    }
                    onClick={() => { setCurrentPage(n) }}
                  >
                    {n}
                  </Link>
              </li>
              ))
            }
            <li>
              <Link
                to="#"
                className="size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"
                onClick={() => { RightShift() }}
              >
                <i className="mdi mdi-chevron-right text-[20px]"></i>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
