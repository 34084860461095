import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import logoDark from "../../assets/images/new-logos/IMOVISOR-dark.png";
import logoLight from "../../assets/images/new-logos/IMOVISOR-light.png";
import bg1 from "../../assets/images/01.jpg";
import Switcher from "../../components/switcher";
import BackToHome from "../../components/backToHome";

import { useAuth } from "../../context/auth";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // verifica se os campos estao preenchidos
      if (!email || !password) {
        setErrorMessage("Preencha todos os campos");
        alert("Preencha todos os campos");
        setLoading(false);
        return;
      }

      // verifica se o email é valido usando regex
      if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
        setErrorMessage("E-mail inválido");
        alert("E-mail inválido");
        setLoading(false);
        return;
      }

      // faz login com supabase
      const { data, error } = await login(email, password);

      if (error) {
        setErrorMessage(error.message);
        alert(error.message);
      }
      if (data.user && data.session) {
        console.log("[+] Working");
        console.log("user: ");
        console.log(data.user);
        console.log("session: ");
        console.log(data.session);
        navigate("/");
      }
    } catch (e) {
      console.log(e);
      setErrorMessage("Email or Password Incorrect");
      alert("Email or Password Incorrect");
    }

    setLoading(false);
  };
  return (
    <>
      <section
        className="h-screen flex items-center justify-center relative overflow-hidden bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
            <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
              <div className="p-6">
                <Link to="">
                  <img
                    src={logoDark}
                    className="mx-auto block dark:hidden"
                    alt=""
                  />
                  <img
                    src={logoLight}
                    className="mx-auto dark:block hidden"
                    alt=""
                  />
                </Link>
                <h5 className="my-6 text-xl font-semibold">
                  Acesse a sua conta
                </h5>
                <form className="text-start">
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-medium" htmlFor="LoginEmail">
                        E-mail:
                      </label>
                      <input
                        id="LoginEmail"
                        type="email"
                        className="form-input mt-3"
                        placeholder="Seu e-mail"
                        autoComplete="username"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-medium" htmlFor="LoginPassword">
                        Senha:
                      </label>
                      <input
                        id="LoginPassword"
                        type="password"
                        className="form-input mt-3"
                        placeholder="Sua senha"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>

                    <div className="flex justify-between mb-4">
                      <div className="flex items-center mb-0">
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-green-600 focus:border-green-300 focus:ring focus:ring-offset-0 focus:ring-green-200 focus:ring-opacity-50 me-2"
                          type="checkbox"
                          value=""
                          id="RememberMe"
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <label
                          className="form-checkbox-label text-slate-400"
                          htmlFor="RememberMe"
                        >
                          Lembrar
                        </label>
                      </div>
                      <p className="text-slate-400 mb-0">
                        <Link to="/reset-password" className="text-slate-400">
                          Esqueceu sua senha ?
                        </Link>
                      </p>
                    </div>

                    <div className="mb-4">
                      <button
                        className="btn bg-green-600 hover:bg-green-700 text-white rounded-md w-full"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        Entrar
                      </button>
                    </div>

                    <div className="text-center">
                      <span className="text-slate-400 me-2">
                        Não possui uma conta ?
                      </span>{" "}
                      <Link
                        to="/signup"
                        className="text-black dark:text-white font-medium"
                      >
                        Registre-se
                      </Link>
                    </div>
                  </div>
                </form>
              </div>

              <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
                <p className="mb-0 text-slate-400">
                  © {new Date().getFullYear()} Imovisor
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BackToHome />
      <Switcher />
    </>
  );
}
